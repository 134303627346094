import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { DataSharingService } from '@phishbowl/shared';
import { filter, mergeMap, share, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  HopControllerService,
  HopDTO,
} from '@phishbowl/header-analyzer-client';
import { HttpClient } from '@angular/common/http';
import { UniformValue } from 'ol/webgl/Helper';
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";

@Component({
  selector: 'ui-hops-table',
  templateUrl: './hops-table.component.html',
  styleUrls: [
    './hops-table.component.scss',
    '../../style.scss'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class HopsTableComponent implements OnInit, AfterViewInit {

  fromIp: string[][] = [[], []];
  byIp: string[][] = [[], []];

  hops!: HopDTO[];
  displayedColumns: string[] = ['order', 'from', 'by', 'with', 'time', 'delay'];
  dataSource: MatTableDataSource<HopDTO> = new MatTableDataSource<HopDTO>();

  spinner: boolean = true;

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild(MatSort)
  sort!: MatSort;

  constructor(
    private dataSharingService: DataSharingService,
    private hopService: HopControllerService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {

    this.dataSharingService.hopsLoading
      .subscribe((loading) => {
        this.spinner = true;
      });

    this.dataSharingService.hops
      .subscribe((hops) => {
        if (hops !== null) {
          this.hops = hops;
          this.dataSource.data = this.hops;
          this.spinner = false;
        } else {
          this.hops = [];
          this.dataSource.data = this.hops;
        }
      });

    this.dataSharingService.fromIp
      .subscribe((fromIp) => {
        if (fromIp !== null) {
          this.fromIp = fromIp;
        } else {
          this.fromIp = [];
        }
      });

    this.dataSharingService.byIp
      .subscribe((byIp) => {
        if (byIp !== null) {
          this.byIp = byIp;
        } else {
          this.byIp = [];
        }
      });

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}
