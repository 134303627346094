import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'ui-header-analyzer',
  templateUrl: './header-analyzer.component.html',
  styleUrls: ['./header-analyzer.component.scss']
})
export class HeaderAnalyzerComponent {
  items: MenuItem[] = [];

  ngOnInit(): void {
    this.menuItems();
  }

  private menuItems() {
    this.items = [
      {
        label: 'Metadata',
        items: [
          {
            label: 'Summary',
            icon: 'pi pi-align-left',
            routerLink: 'summary',
          },
          {
            label: 'Hops',
            icon: 'pi pi-sitemap',
            routerLink: 'hops',
          },
          {
            label: 'Auth.',
            icon: 'pi pi-check-circle',
            routerLink: 'auth',
          },
          {
            label: 'Headers',
            icon: 'pi pi-code',
            routerLink: 'headers',
          },
        ],
      },
    ];
  }
  
}
