import { Component } from '@angular/core';

@Component({
  selector: 'phish-bowl-ui-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss']
})
export class BodyComponent {

}
