import { KeycloakService } from 'keycloak-angular';

export function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: 'https://sso.cyberseas-io.eu/',
        realm: 'CyberSEAS',
        // personalize the client id in base of the application
        //set localhost or the domain of the application in the client for redirect page
        clientId: 'sed',
        //clientId: 'sed-local',
      },
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false
      },
      bearerExcludedUrls: ['/assets', '/clients/public']
    });
}
