import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataSharingService } from '@phishbowl/shared';
import { URLAnalysis } from '@phishbowl/body-analyzer-client';


@Component({
  selector: 'ui-whois-v2',
  templateUrl: './whois-v2.component.html',
  styleUrls: ['./whois-v2.component.scss',
    '../../style.scss'
  ],
  encapsulation: ViewEncapsulation.None,
})

export class WhoisV2Component implements OnInit {

  date: Date = new Date();
  spinner: boolean = true;
  analysis: URLAnalysis[] = [];

  constructor(
    public dataSharingService: DataSharingService,
  ) { }

  ngOnInit(): void {

    this.dataSharingService.urlAnalysisLoadind
      .subscribe((loading) => {
        // Use the loading state to display/hide the loading spinner
        this.spinner = true;
      });

    this.dataSharingService.urlAnalysis
      .subscribe((urlAnalysis) => {
        if (urlAnalysis !== null) {
          // Update the analysis only when it's not null
          this.analysis = urlAnalysis;
          // count if some url.whois?.created > 200 days
/*           let count = 0;
          this.analysis.forEach((url) => {

            //if whois is null
            if (!url.whois) {
              count++;
            }

            if (url.whois?.created && this.differenceInDays(url.whois?.created) < 200) {
              count++;
            }

          });

          this.dataSharingService.whoIsThreat.next(count); */
          this.spinner = false;
        } else {
          // Handle the case when marleneAnalysis is null (e.g., show a placeholder) Or any appropriate default value
          this.analysis = [];
        }
      });

  }

  differenceInDays(date: string): number {
    return Math.floor((new Date().getTime() - new Date(date).getTime()) / (1000 * 3600 * 24));
  }

  removeWWW(url: string): string {
    return url.replace('www.', '');
  }

  /* order array of object */
  orderArray(array: any[], key: string): any[] {
    return array.sort((a, b) => {
      if (a[key] < b[key]) {
        return -1;
      } else if (a[key] > b[key]) {
        return 1;
      } else {
        return 0;
      }
    });
  }

}
