import { Injectable, OnInit } from '@angular/core';

interface Event {
  status: string;
  icon: string;
  color: string;
  description: string;
}

@Injectable({
  providedIn: 'root'
})
export class EventService implements OnInit {
  private events: Event[] = [];
  private totalEvents: number = 0;

  // header
  private headerThreat = 0;
  private headerWarning = 0;

  //body
  private bodyThreat = 0;
  private bodyWarning = 0;
  private alidaThreat = 0;
  private marleneThreat = 0;
  private marleneWarning = 0;
  private whoIsWarning = 0;
  private certificateWarning = 0;
  private snapshotWarning = 0;

  //attachment
  private attachmentThreat = 0;


  //description
/*   authFailed = 'Authentication failed';
  hopdDelays = 'Delays in hops'; */
  authFailed = '';
  hopdDelays = '';
  alidaDescription = '';
  marleneDescriptionPhishing = '';
  marleneDescriptionPolicy = '';
  urlsMalicious = '';
  fileMalicious = '';
  whoIsDescription = '';
  certificateDescription = '';
  snapshotDescription = '';


  constructor() { }
  ngOnInit(): void {
    // initialize the events array
    this.addEvent({ status: 'Header Analysis: No Threat', icon: 'pi pi-check-circle', color: 'green', description: '' });
    this.addEvent({ status: 'Body Analysis: No Threat', icon: 'pi pi-check-circle', color: 'green', description: ''});
    this.addEvent({ status: 'Attachment Analysis: No Threat', icon: 'pi pi-check-circle', color: 'green', description: ''});
    this.addEvent({ status: 'Final Score: Pass', icon: 'pi pi-check-circle', color: 'green', description: ''});
  }

  addEvent(event: Event) {
    this.events.push(event);
  }

  getEvents(): Event[] {
    return this.events;
  }

  getEventByIndex(index: number): Event {
    return this.events[index];
  }

  resetEvents() {
    this.events = [];
    this.addEvent({ status: 'Header Analysis: No Threat', icon: 'pi pi-check-circle', color: 'green', description: ''});
    this.addEvent({ status: 'Body Analysis: No Threat', icon: 'pi pi-check-circle', color: 'green', description: ''});
    this.addEvent({ status: 'Attachment Analysis: No Threat', icon: 'pi pi-check-circle', color: 'green', description: ''});
    this.addEvent({ status: 'Status:  ' + this.getTotalEvents() + ' events detected', icon: 'pi pi-shield', color: 'green', description: 'No events detected'});

    this.authFailed = '';
    this.hopdDelays = '';
    this.alidaDescription = '';
    this.marleneDescriptionPhishing = '';
    this.marleneDescriptionPolicy = '';
    this.urlsMalicious = '';
    this.fileMalicious = '';
    this.whoIsDescription = '';
    this.certificateDescription = '';
    this.snapshotDescription = '';

  }

  //update event by index
  updateEvent(index: number, event: Event) {
    this.events[index] = event;
  }

  getTotalEvents(): number {
    return this.totalEvents;
  }

  setTotalEvents(totalEvents: number) {
    this.totalEvents = totalEvents;
  }

  resetTotalEvents() {
    this.totalEvents = 0;
  }

  //header
  getHeaderThreat(): number {
    return this.headerThreat;
  }

  setHeaderThreat(headerThreat: number) {
    this.headerThreat = headerThreat;
  }

  getHeaderWarning(): number {
    return this.headerWarning;
  }

  setHeaderWarning(headerWarning: number) {
    this.headerWarning = headerWarning;
  }

  resetHeader() {
    this.headerThreat = 0;
    this.headerWarning = 0;
  }

  //body
  getBodyThreat(): number {
    return this.bodyThreat;
  }

  setBodyThreat(bodyThreat: number) {
    this.bodyThreat = bodyThreat;
  }

  getBodyWarning(): number {
    return this.bodyWarning;
  }

  setBodyWarning(bodyWarning: number) {
    this.bodyWarning = bodyWarning;
  }

  getAlidaThreat(): number {
    return this.alidaThreat;
  }

  setAlidaThreat(alidaThreat: number) {
    this.alidaThreat = alidaThreat;
  }

  getMarleneThreat(): number {
    return this.marleneThreat;
  }

  setMarleneThreat(marleneThreat: number) {
    this.marleneThreat = marleneThreat;
  }

  getMarleneWarning(): number {
    return this.marleneWarning;
  }

  setMarleneWarning(marleneWarning: number) {
    this.marleneWarning = marleneWarning;
  }

  resetBody() {
    this.bodyThreat = 0;
    this.bodyWarning = 0;
    this.alidaThreat = 0;
    this.marleneThreat = 0;
    this.marleneWarning = 0;
    this.whoIsWarning = 0;
    this.certificateWarning = 0;
    this.snapshotWarning = 0;
  }

  //attachment
  getAttachmentThreat(): number {
    return this.attachmentThreat;
  }

  setAttachmentThreat(attachmentThreat: number) {
    this.attachmentThreat = attachmentThreat;
  }

  resetAttachment() {
    this.attachmentThreat = 0;
  }

  getAuthFailed(): string {
    return this.authFailed;
  }

  setAuthFailed(authFailed: string) {
    this.authFailed = authFailed;
  }

  getHopDelays(): string {
    return this.hopdDelays;
  }

  setHopDelays(hopdDelays: string) {
    this.hopdDelays = hopdDelays;
  }

  getAlidaDescription(): string {
    return this.alidaDescription;
  }

  setAlidaDescription(alidaDescription: string) {
    this.alidaDescription = alidaDescription;
  }

  getMarleneDescriptionMalicious(): string {
    return this.marleneDescriptionPhishing;
  }

  setMarleneDescriptionMalicious(marleneDescriptionPhishing: string) {
    this.marleneDescriptionPhishing = marleneDescriptionPhishing;
  }

  getMarleneDescriptionPolicy(): string {
    return this.marleneDescriptionPolicy;
  }

  setMarleneDescriptionPolicy(marleneDescriptionPolicy: string) {
    this.marleneDescriptionPolicy = marleneDescriptionPolicy;
  }

  getUrlsMalicious(): string {
    return this.urlsMalicious;
  }

  setUrlsMalicious(urlsMalicious: string) {
    this.urlsMalicious = urlsMalicious;
  }

  getFileMalicious(): string {
    return this.fileMalicious;
  }

  setFileMalicious(fileMalicious: string) {
    this.fileMalicious = fileMalicious;
  }

  getWhoIsDescription(): string {
    return this.whoIsDescription;
  }

  setWhoIsDescription(whoIsDescription: string) {
    this.whoIsDescription = whoIsDescription;
  }

  getWhoIsWarning(): number {
    return this.whoIsWarning;
  }

  setWhoIsWarning(whoIsWarning: number) {
    this.whoIsWarning = whoIsWarning;
  }

  getCertificateDescription(): string {
    return this.certificateDescription;
  }

  setCertificateDescription(certificateDescription: string) {
    this.certificateDescription = certificateDescription;
  }

  getCertificateWarning(): number {
    return this.certificateWarning;
  }

  setCertificateWarning(certificateWarning: number) {
    this.certificateWarning = certificateWarning;
  }

  getSnapshotDescription(): string {
    return this.snapshotDescription;
  }

  setSnapshotDescription(snapshotDescription: string) {
    this.snapshotDescription = snapshotDescription;
  }

  getSnapshotWarning(): number {
    return this.snapshotWarning;
  }

  setSnapshotWarning(snapshotWarning: number) {
    this.snapshotWarning = snapshotWarning;
  }

  // Add other methods as needed, like removeEvent, updateEvent, etc.
}
