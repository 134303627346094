import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { DataSharingService } from '@phishbowl/shared';
import { filter, mergeMap, share, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UrlControllerService, URLAnalysis } from '@phishbowl/body-analyzer-client';
import { Utils } from '../../utils';
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";


@Component({
  selector: 'ui-url-analyzer',
  templateUrl: './url-analyzer.component.html',
  styleUrls: ['./url-analyzer.component.scss',
    '../../style.scss'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class UrlAnalyzerComponent implements OnInit, AfterViewInit {

  //urls: URLAnalysis[] = [];
  utils = new Utils();
  spinner: boolean = true;
  analysis: URLAnalysis[] = [];

  //displayedColumns: string[] = ['text', 'url', 'protocol', 'host', 'path', 'query', 'blacklist', 'whitelist'];
  displayedColumns: string[] = ['text', 'url', 'protocol', 'path', 'query', 'blacklist', 'whitelist'];

  dataSource: MatTableDataSource<URLAnalysis> = new MatTableDataSource<URLAnalysis>();

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort = new MatSort;

  constructor(
    public dataSharingService: DataSharingService,
  ) { }

  ngOnInit(): void {

    this.dataSharingService.urlAnalysisLoadind
      .subscribe((loading) => {
        // Use the loading state to display/hide the loading spinner
        this.spinner = true;
      });

    this.dataSharingService.urlAnalysis
      .subscribe((urlAnalysis) => {
        if (urlAnalysis !== null) {
          // Update the analysis only when it's not null
          this.analysis = urlAnalysis;
          console.log(urlAnalysis);
          //count the number of blacklisted urls
          let count = 0;
          this.analysis.forEach((url) => {
            if (url.references && url.references.length > 0) {
              count++;
            }
          });
          //this.dataSharingService.urlsBlocklisted.next(count);
          this.spinner = false;
        } else {
          // Handle the case when marleneAnalysis is null (e.g., show a placeholder) Or any appropriate default value
          this.analysis = [];
        }
      });

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}
