<h2>Urls Analysis</h2>
<div *ngIf="spinner; else urlAnalisysBody" class="pl-4">
    <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i> LOADING...
</div>

<ng-template #urlAnalisysBody>

    <div *ngIf="analysis.length < 1 else table" class="pl-4 fade-in-left">
        No Urls Found in the body of the email.
    </div>

    <ng-template #table>


        <div class="fade-in-down">

            <table mat-table [dataSource]="utils.orderArray(analysis, 'url')" matSort matSortDisableClear>

                <ng-container matColumnDef="text">
                    <th mat-header-cell *matHeaderCellDef>Text</th>
                    <td mat-cell *matCellDef="let url">
                        <span *ngIf="url?.text?.length > 0; else noHost" [matTooltip]="url.text"
                            matTooltipPosition="above">{{url.text }}</span>
                        <ng-template #noHost>-</ng-template>
                    </td>
                </ng-container>

                <ng-container matColumnDef="url">
                    <th mat-header-cell *matHeaderCellDef>Url</th>
                    <td mat-cell *matCellDef="let url"><span [matTooltip]="url.url" matTooltipPosition="above">{{
                            url.url }}</span></td>
                </ng-container>

                <ng-container matColumnDef="protocol">
                    <th mat-header-cell *matHeaderCellDef>Protocol</th>
                    <td mat-cell *matCellDef="let url">
                        <span *ngIf=" url.protocol === 'https'; else httpBlock ">
                            <p-tag severity="Primary" [value]="url.protocol"></p-tag>
                        </span>
                        <ng-template #httpBlock>
                            <p-tag severity="warning" [value]="url.protocol"></p-tag>
                        </ng-template>
                    </td>
                </ng-container>

                <ng-container matColumnDef="host">
                    <th mat-header-cell *matHeaderCellDef>Host</th>
                    <td mat-cell *matCellDef="let url">
                        <span [matTooltip]="url.host" matTooltipPosition="above">{{url.host || '-'}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="path">
                    <th mat-header-cell *matHeaderCellDef>Path</th>
                    <td mat-cell *matCellDef="let url"><span>{{ url.path || '-'}}</span></td>
                </ng-container>

                <ng-container matColumnDef="query">
                    <th mat-header-cell *matHeaderCellDef>Query</th>
                    <td mat-cell *matCellDef="let url">
                        {{ url.query || '-' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="blacklist">
                    <th mat-header-cell *matHeaderCellDef> <span
                            style="display: flex; justify-content: center; align-items: center;">
                            Blacklist
                        </span></th>
                    <td mat-cell *matCellDef="let url" class="">
                        <span *ngIf="url.references?.length > 0; then thenBlock else elseBlock"></span>
                        <ng-template #thenBlock><!-- <mat-icon matTooltip="Found in a Blacklist {{url.references}}"
                                matTooltipPosition="above" mat-list-icon class="fail">cancel</mat-icon> -->
                            <i class="pi pi-times fail" styleClass="icon-fail" pTooltip="Found in a Blacklist"
                                tooltipPosition="top"
                                style="display: flex; justify-content: center; align-items: center;"></i>
                        </ng-template>
                        <ng-template #elseBlock><!-- <mat-icon matTooltip="Not in a Blacklist" matTooltipPosition="above"
                                mat-list-icon class="pass">check_circle</mat-icon> -->
                            <i class="pi pi-check-circle pass" styleClass="icon-pass" pTooltip="Not in a Blacklist"
                                tooltipPosition="top"
                                style="display: flex; justify-content: center; align-items: center;"></i>
                        </ng-template>
                    </td>
                </ng-container>

                <ng-container matColumnDef="whitelist">
                    <th mat-header-cell *matHeaderCellDef>
                        <span style="display: flex; justify-content: center; align-items: center;">
                            Whitelist
                        </span>
                    </th>
                    <td mat-cell *matCellDef="let url" class="">
                        <span *ngIf="url.tranco?.length > 0; then thenBlock2 else elseBlock2"></span>
                        <ng-template #thenBlock2 class="grid grid-cols-1 place-content-center">
                            <!--                             <mat-icon matTooltip="Found in a Whitelist {{url.tranco.domain}}" matTooltipPosition="above"
                                mat-list-icon class="pass">check_circle</mat-icon> -->
                            <i class="pi pi-check-circle icon-pass pass"
                                pTooltip="Found in a Whitelist {{url.tranco.domain}}" tooltipPosition="top"
                                style="display: flex; justify-content: center; align-items: center;"></i>
                        </ng-template>
                        <ng-template #elseBlock2><span matTooltip="Not in a Whitelist" matTooltipPosition="above"
                                mat-list-icon
                                style="display: flex; justify-content: center; align-items: center;">-</span>
                        </ng-template>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[3, 5, 10, 20]" [pageSize]="10"></mat-paginator>
<!-- 
            <p-table [value]="analysis" [paginator]="true" [rows]="10" [rowsPerPageOptions]="[3, 5, 10, 20]"
                [tableStyle]="{'min-width': '50rem'}">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Text</th>
                        <th>Url</th>
                        <th>Protocol</th>
                        <th>Path</th>
                        <th>Query</th>
                        <th>Blacklist</th>
                        <th>Whitelist</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-url>
                    <tr>
                        <td>{{ url.text || '-' }}</td>
                        <td>{{ url.url || '-' }}</td>
                        <td style="display: flex; justify-content: center; align-items: center;">
                            <p-tag [severity]="url.protocol === 'https' ? 'success' : 'warning'"
                                [value]="url.protocol"></p-tag>
                        </td>
                        <td>
                            <div class="flex justify-center">
                                {{ url.path || '-' }}
                            </div>
                        </td>
                        <td>
                            <div class="flex justify-center">
                                {{ url.query || '-' }}
                            </div>
                        </td>
                        <td>
                            <ng-container *ngIf="url.references?.length > 0; else noBlacklist">
                                <i class="pi pi-times" styleClass="icon-fail" pTooltip="Found in a Blacklist"
                                    tooltipPosition="top"
                                    style="display: flex; justify-content: center; align-items: center;"></i>
                            </ng-container>
                            <ng-template #noBlacklist>
                                <i class="pi pi-check-circle" styleClass="icon-pass" pTooltip="Not in a Blacklist"
                                    tooltipPosition="top"
                                    style="display: flex; justify-content: center; align-items: center;"></i>
                            </ng-template>
                        </td>
                        <td>
                            <ng-container *ngIf="url.tranco && url.tranco.domain; else noWhitelist">
                                <i class="pi pi-check-circle icon-pass"
                                    pTooltip="Found in a Whitelist {{url.tranco.domain}}" tooltipPosition="top"
                                    style="display: flex; justify-content: center; align-items: center;"></i>
                            </ng-container>
                            <ng-template #noWhitelist>
                                <i class="pi pi-remove" pTooltip="Not in a Whitelist" tooltipPosition="top"
                                    style="display: flex; justify-content: center; align-items: center;"></i>
                            </ng-template>
                        </td>

                    </tr>
                </ng-template>
            </p-table>
 -->

        </div>


    </ng-template>
</ng-template>