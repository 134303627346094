import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TextAnalysis } from '@phishbowl/body-analyzer-client';
import { DataSharingService } from '@phishbowl/shared';
import { Subscription } from 'rxjs';


@Component({
  selector: 'ui-alida',
  templateUrl: './alida.component.html',
  styleUrls: ['./alida.component.scss',
    '../../style.scss'
  ],
  encapsulation: ViewEncapsulation.None,
  //providers: [MessageService]
})
export class AlidaComponent implements OnInit {

  value: number = 0;
  radarChartOptions: any;
  sentimentAnalysisData: any;
  analysis: TextAnalysis = {};
  labels: String[] = [];
  scores: number[] = [];
  spinner: boolean = true;
  subscriptions: Subscription[] = [];

  constructor(
    public dataSharingService: DataSharingService,
  ) { }

  ngOnInit(): void {

    this.dataSharingService.textAnalysisLoadind
    .subscribe((loading) => {
      // Use the loading state to display/hide the loading spinner
      this.spinner = true;
    });

  this.dataSharingService.textAnalysis
    .subscribe((analysis) => {
      if (analysis !== null) {
        // Update the analysis only when it's not null
        this.analysis = analysis;

        /* LOGIC */

        if (this.analysis.alidaResponse?.outputs?.prediction) {
          this.value = this.scaleAndRound(this.analysis.alidaResponse?.outputs?.prediction[0][0]);
        }

        //put labels and score from emoroberta into array
        this.labels = []; //clear array
        this.analysis.emoRoBERTaResponse?.forEach((element) => {
          element.forEach((element2) => {
            if (element2.score && this.scaleAndRound(element2.score) > 0)
              this.labels.push(element2.label!);
          });
        });
        this.scores = []; //clear array
        this.analysis.emoRoBERTaResponse?.forEach((element) => {
          element.forEach((element2) => {
            if (element2.score && this.scaleAndRound(element2.score) > 0)
              this.scores.push(this.scaleAndRound(element2.score!));
          });
        });

        this.sentimentAnalysisData = {
          labels: this.labels,
          datasets: [
            {
              label: 'Sentiment Analysis',
              backgroundColor: 'rgba(179,181,198,0.2)',
              borderColor: 'rgba(179,181,198,1)',
              pointBackgroundColor: 'rgba(179,181,198,1)',
              pointBorderColor: '#fff',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: 'rgba(179,181,198,1)',
              data: this.scores,
            },
          ],
        };

        /* END LOGIC */

        this.spinner = false;

      } else {
        // Handle the case when marleneAnalysis is null (e.g., show a placeholder) Or any appropriate default value
        this.analysis = {};
      }
    });

    this.radarChartOptions = {
      indexAxis: 'y',
      maintainAspectRatio: false,
      aspectRatio: 1.4,
      scales: {
        x: {
          ticks: {
            //color: textColorSecondary,
            font: {
              weight: 500
            }
          },
          grid: {
            //color: surfaceBorder,
            drawBorder: false
          }
        },
        y: {
          ticks: {
            //color: textColorSecondary
          },
          grid: {
            //color: surfaceBorder,
            drawBorder: false
          }
        }
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    }

  }

  scaleAndRound(value: number): number {
    return Math.round(value * 100);
  }

}
