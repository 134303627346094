import { Component, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MalwareAnalyzerApiControllerService } from '@phishbowl/malware-analyzer-client';
import { FileUpload } from 'primeng/fileupload';


interface UploadEvent {
  originalEvent: Event;
  files: File[];
}

@Component({
  selector: 'phish-bowl-ui-malware-analyzer',
  templateUrl: './malware-analyzer.component.html',
  styleUrls: ['./malware-analyzer.component.scss']
})
export class MalwareAnalyzerComponent {

  uploadedFiles: any[] = [];
  scannedFiles: any[] = [];
  loading = false;
  cntTot = 0;
  cntScanned = 0;

  @ViewChild(FileUpload) fileUpload: FileUpload | undefined;


  constructor(
    private messageService: MessageService,
    private malwareAnalyzerApiControllerService: MalwareAnalyzerApiControllerService
  ) { }

  myUploader(event: UploadEvent) {

    //clear all
    this.uploadedFiles = [];
    this.scannedFiles = [];
    this.cntScanned = 0;
    this.cntTot = 0;

    this.loading = true;

    for (let file of event.files) {
      this.uploadedFiles.push(file);
      this.cntTot++;
    }

    for (let file of event.files) {

      this.malwareAnalyzerApiControllerService
        .analyze(file)
        .subscribe((analysis) => {
          this.scannedFiles.push(analysis);

          if (this.cntTot === this.scannedFiles.length) {
            this.loading = false;
            this.cntScanned++;
          }else{
            this.cntScanned++;
          }

        })

    }

    //clear files
    this.fileUpload!.clear();

  }

  onClearHandler(event: Event) {
    //clear all
    this.uploadedFiles = [];
    this.scannedFiles = [];
  }

}
