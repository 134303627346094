import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DataSharingService } from '../../data-sharing/data-sharing.service';
import { ReportControllerService } from '@phishbowl/sed-report-client';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakTokenParsed } from 'keycloak-js';
import { HopDTO } from '@phishbowl/header-analyzer-client';
import { AuthenticationDTO } from '@phishbowl/header-analyzer-client';
import { TextAnalysis } from '@phishbowl/body-analyzer-client';
import { MarleneResponse } from '@phishbowl/body-analyzer-client';
import { EventService } from '../../data-sharing/event-service.service';
import { MalwareAnalysis } from '@phishbowl/malware-analyzer-client';
import { URLAnalysis } from '@phishbowl/body-analyzer-client';
import { map } from 'rxjs';
import { MatSnackBar } from "@angular/material/snack-bar";



@Component({
  selector: 'ui-user-report',
  templateUrl: './user-report.component.html',
  styleUrls: ['./user-report.component.scss',
    '../../style.scss'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class UserReportComponent implements OnInit {

  hops!: HopDTO[];
  auth: AuthenticationDTO = {};
  analysis1: TextAnalysis = {};
  analysis2: MalwareAnalysis = {};
  analysis3: URLAnalysis[] = [];

  events: any[] = [];

  spinner = false;
  sendingReport = false;
  reportSent = false;
  reportUID = '';

  userProfile: KeycloakTokenParsed | undefined;

  constructor(
    private snackBar: MatSnackBar,
    private messageService: MessageService,
    public dataSharingService: DataSharingService,
    private reportControllerService: ReportControllerService,
    //private reportControllerService: ReportControllerService // Add ReportControllerService as a dependency
    private keycloakService: KeycloakService,
    public eventService: EventService // Inject the EventService here
  ) {

    /* 
        this.events = [
          { status: 'Header: ' + dataSharingService.hopsDelay.getValue() + ' Threat, ' + dataSharingService.authFailed.getValue() + ' Warning', icon: 'pi pi-exclamation-triangle', color: '#9C27B0', image: 'game-controller.jpg' },
          { status: 'Body: 1 Threat, 3 Warning', icon: 'pi pi-exclamation-circle', color: '#673AB7' },
          { status: 'Attach.: 1 Threat', icon: 'pi pi-exclamation-circle', color: '#FF9800' },
          { status: 'Status: High phishing probabilities', icon: 'pi pi-shield', color: '#FF9800' },
        ]; */
  }

  ngOnInit(): void {

    if (this.keycloakService.isLoggedIn()) {
      this.userProfile = this.keycloakService.getKeycloakInstance().idTokenParsed;
    }

    // subscribe reportUID datasharing
    if (this.dataSharingService.reportUID.getValue()) {
      this.dataSharingService.reportUID
        .subscribe((reportUID) => {
          if (reportUID !== null) {
            this.reportUID = reportUID;
          }
        });
    }

    //report sent value from datasharing service
    this.dataSharingService.reportSent
      .subscribe((reportSent) => {
        this.reportSent = reportSent;
      });

    //reset events
    //this.eventService.resetEvents();

    if (this.eventService.getEvents.length === 0) {

      // auth
      this.dataSharingService.auth
        .subscribe((auth) => {
          if (auth !== null) {
            // Update the analysis only when it's not null
            this.auth = auth;

            let count = 0;
            //check if spf is not pass, increment count
            if (this.auth.spf && this.auth.spf !== 'Pass') {
              count++;
            }
            //check if dkim is not pass, increment count
            if (this.auth.dkim && this.auth.dkim !== 'Pass') {
              count++;
            }
            //check if arc is not pass, increment count
            if (this.auth.dmarc && this.auth.dmarc !== 'Pass') {
              count++;
            }

            //this.dataSharingService.authFailed.next(count);
            this.eventService.setHeaderWarning(count);
            if (count > 0) {
              this.eventService.setTotalEvents(this.eventService.getHeaderThreat() + this.eventService.getHeaderWarning() + this.eventService.getBodyThreat() + this.eventService.getBodyWarning() + this.eventService.getAttachmentThreat() + this.eventService.getAlidaThreat() + this.eventService.getMarleneThreat() + this.eventService.getMarleneWarning());
              this.eventService.setAuthFailed('<p>Authentication Headers failed;');
              this.eventService.updateEvent(0, {
                status: 'Header Analysis: ' + this.eventService.getHeaderThreat() + ' Threat, ' + this.eventService.getHeaderWarning() + ' Warning',
                icon: 'pi pi-exclamation-circle',
                color: 'orange',
                description: this.eventService.getAuthFailed() + this.eventService.getHopDelays()
              });
              this.eventService.updateEvent(3, {
                status: 'Status: ' + this.eventService.getTotalEvents() + ' events detected',
                icon: 'pi pi-shield',
                color: 'red',
                description: this.computeTotalScore()
              });
            }
          }
        });

      // hops
      this.dataSharingService.hops
        .subscribe((hops) => {
          if (hops !== null) {
            this.hops = hops;
            let count = 0;
            this.hops.forEach((hop) => {
              if (hop.delay !== null && hop.delay != undefined && hop.delay > 120000) {
                count++;
              }
            });
            //this.dataSharingService.hopsDelay.next(count);
            this.eventService.setHeaderThreat(count);
            if (count > 0) {
              this.eventService.setTotalEvents(this.eventService.getHeaderThreat() + this.eventService.getHeaderWarning() + this.eventService.getBodyThreat() + this.eventService.getBodyWarning() + this.eventService.getAttachmentThreat() + this.eventService.getAlidaThreat() + this.eventService.getMarleneThreat() + this.eventService.getMarleneWarning());
              this.eventService.setHopDelays('<p>Hops Delayed;');
              this.eventService.updateEvent(0, {
                status: 'Header Analysis: ' + this.eventService.getHeaderThreat() + ' Threat, ' + this.eventService.getHeaderWarning() + ' Warning',
                icon: 'pi pi-exclamation-triangle',
                color: 'red',
                description: this.eventService.getAuthFailed() + this.eventService.getHopDelays()
              });
              this.eventService.updateEvent(3, {
                status: 'Status: ' + this.eventService.getTotalEvents() + ' events detected',
                icon: 'pi pi-shield',
                color: 'red',
                description: this.computeTotalScore()
              });
            }
          }
        });

      // whois
      this.dataSharingService.urlAnalysis
        .subscribe((urlAnalysis) => {
          if (urlAnalysis !== null) {
            // count if some url.whois?.created > 200 days
            let count = 0;
            urlAnalysis.forEach((url) => {
              //if whois is null
              if (!url.whois) {
                count++;
              }
              if (url.whois?.created && this.differenceInDays(url.whois?.created) < 200) {
                count++;
              }
            });
            this.eventService.setWhoIsWarning(count);
            if (count > 0) {
              this.eventService.setTotalEvents(this.eventService.getHeaderThreat() + this.eventService.getHeaderWarning() + this.eventService.getBodyThreat() + this.eventService.getBodyWarning() + this.eventService.getAttachmentThreat() + this.eventService.getAlidaThreat() + this.eventService.getMarleneThreat() + this.eventService.getMarleneWarning() + this.eventService.getWhoIsWarning() + this.eventService.getCertificateWarning() + this.eventService.getSnapshotWarning());
              this.eventService.setWhoIsDescription('<p>URLs with whois warning;');
              this.eventService.updateEvent(1, {
                status: 'Body Analysis: ' + (this.eventService.getBodyThreat() + this.eventService.getAlidaThreat() + this.eventService.getMarleneThreat()) + ' Threat, ' + (this.eventService.getBodyWarning() + this.eventService.getMarleneWarning() + this.eventService.getWhoIsWarning() + this.eventService.getCertificateWarning() + this.eventService.getSnapshotWarning()) + ' Warning',
                icon: 'pi pi-exclamation-circle',
                color: 'orange',
                description: this.eventService.getAlidaDescription() + this.eventService.getMarleneDescriptionMalicious() + this.eventService.getMarleneDescriptionPolicy() + this.eventService.getUrlsMalicious() + this.eventService.getWhoIsDescription() + this.eventService.getCertificateDescription() + this.eventService.getSnapshotDescription()
              });
              this.eventService.updateEvent(3, {
                status: 'Status: ' + this.eventService.getTotalEvents() + ' events detected',
                icon: 'pi pi-shield',
                color: 'red',
                description: this.computeTotalScore()
              });
            }
          }
        });

      //certificates
      this.dataSharingService.urlAnalysis
        .subscribe((urlAnalysis) => {
          if (urlAnalysis !== null) {
            // count if some url miss certificates
            let countCert = 0;
            urlAnalysis.forEach((url) => {
              if (!url.certificates || url.certificates.length === 0) {
                countCert++;
              }
            });
            this.eventService.setCertificateWarning(countCert);
            if (countCert > 0) {
              this.eventService.setTotalEvents(this.eventService.getHeaderThreat() + this.eventService.getHeaderWarning() + this.eventService.getBodyThreat() + this.eventService.getBodyWarning() + this.eventService.getAttachmentThreat() + this.eventService.getAlidaThreat() + this.eventService.getMarleneThreat() + this.eventService.getMarleneWarning() + this.eventService.getWhoIsWarning() + this.eventService.getCertificateWarning() + this.eventService.getSnapshotWarning());
              this.eventService.setCertificateDescription('<p>URLs without certificates;');
              this.eventService.updateEvent(1, {
                status: 'Body Analysis: ' + (this.eventService.getBodyThreat() + this.eventService.getAlidaThreat() + this.eventService.getMarleneThreat()) + ' Threat, ' + (this.eventService.getBodyWarning() + this.eventService.getMarleneWarning() + this.eventService.getWhoIsWarning() + this.eventService.getCertificateWarning() + this.eventService.getSnapshotWarning()) + ' Warning',
                icon: 'pi pi-exclamation-circle',
                color: 'orange',
                description: this.eventService.getAlidaDescription() + this.eventService.getMarleneDescriptionMalicious() + this.eventService.getMarleneDescriptionPolicy() + this.eventService.getUrlsMalicious() + this.eventService.getWhoIsDescription() + this.eventService.getCertificateDescription() + this.eventService.getSnapshotDescription()
              });
              this.eventService.updateEvent(3, {
                status: 'Status: ' + this.eventService.getTotalEvents() + ' events detected',
                icon: 'pi pi-shield',
                color: 'red',
                description: this.computeTotalScore()
              });
            }
          }
        });

      //snapshot
      this.dataSharingService.urlAnalysis
        .subscribe((urlAnalysis) => {
          if (urlAnalysis !== null) {
            // count if some url miss snapshot
            let countSnapshot = 0;
            urlAnalysis.forEach((url) => {
              if (!url.snapshots || url.snapshots.length === 0) {
                countSnapshot++;
              }
            });
            this.eventService.setSnapshotWarning(countSnapshot);
            if (countSnapshot > 0) {
              this.eventService.setTotalEvents(this.eventService.getHeaderThreat() + this.eventService.getHeaderWarning() + this.eventService.getBodyThreat() + this.eventService.getBodyWarning() + this.eventService.getAttachmentThreat() + this.eventService.getAlidaThreat() + this.eventService.getMarleneThreat() + this.eventService.getMarleneWarning() + this.eventService.getWhoIsWarning() + this.eventService.getCertificateWarning() + this.eventService.getSnapshotWarning());
              this.eventService.setSnapshotDescription('<p>URLs without snapshots;');
              this.eventService.updateEvent(1, {
                status: 'Body Analysis: ' + (this.eventService.getBodyThreat() + this.eventService.getAlidaThreat() + this.eventService.getMarleneThreat()) + ' Threat, ' + (this.eventService.getBodyWarning() + this.eventService.getMarleneWarning() + this.eventService.getWhoIsWarning() + this.eventService.getCertificateWarning() + this.eventService.getSnapshotWarning()) + ' Warning',
                icon: 'pi pi-exclamation-circle',
                color: 'orange',
                description: this.eventService.getAlidaDescription() + this.eventService.getMarleneDescriptionMalicious() + this.eventService.getMarleneDescriptionPolicy() + this.eventService.getUrlsMalicious() + this.eventService.getWhoIsDescription() + this.eventService.getCertificateDescription() + this.eventService.getSnapshotDescription()
              });
              this.eventService.updateEvent(3, {
                status: 'Status: ' + this.eventService.getTotalEvents() + ' events detected',
                icon: 'pi pi-shield',
                color: 'red',
                //description: 'High phishing probabilities' 
                description: this.computeTotalScore()
              });
            }
          }
        });

      // marlene 
      this.dataSharingService.marleneAnalysis
        .subscribe((marleneAnalysis) => {
          if (marleneAnalysis !== null) {
            // Iterate over each key in the results object
            for (const key in marleneAnalysis.results) {
              if (marleneAnalysis.results.hasOwnProperty(key)) {
                const classifications = marleneAnalysis.results[key]; // Array of classifications
                // Iterate over each classification in the array
                for (const classification of classifications) {
                  // Check for PhishingClassification and if it's not "NOT-Malicious General Class"

                  // Check for PolicyViolationClassification and if it's not "NOT-PolicyViolation"
                  if (classification.hasOwnProperty('PolicyViolationClassification') &&
                    classification['PolicyViolationClassification'] !== "NOT-PolicyViolation") {
                    this.eventService.setTotalEvents(this.eventService.getHeaderThreat() + this.eventService.getHeaderWarning() + this.eventService.getBodyThreat() + this.eventService.getBodyWarning() + this.eventService.getAttachmentThreat() + this.eventService.getAlidaThreat() + this.eventService.getMarleneThreat() + this.eventService.getMarleneWarning() + this.eventService.getWhoIsWarning() + this.eventService.getCertificateWarning() + this.eventService.getSnapshotWarning());
                    this.eventService.setMarleneDescriptionPolicy('<p>Marlene Analysis: classified as Policy Violation;');
                    //this.dataSharingService.marlenePolicy.next(true);
                    this.eventService.setMarleneWarning(1);
                    this.eventService.updateEvent(1, {
                      status: 'Body Analysis: ' + (this.eventService.getBodyThreat() + this.eventService.getAlidaThreat() + this.eventService.getMarleneThreat()) + ' Threat, ' + (this.eventService.getBodyWarning() + this.eventService.getMarleneWarning() + this.eventService.getWhoIsWarning() + this.eventService.getCertificateWarning() + this.eventService.getSnapshotWarning()) + ' Warning',
                      icon: 'pi pi-exclamation-circle',
                      color: 'orange',
                      description: this.eventService.getAlidaDescription() + this.eventService.getMarleneDescriptionMalicious() + this.eventService.getMarleneDescriptionPolicy() + this.eventService.getUrlsMalicious() + this.eventService.getWhoIsDescription() + this.eventService.getCertificateDescription() + this.eventService.getSnapshotDescription()
                    });
                    this.eventService.updateEvent(3, {
                      status: 'Status: ' + this.eventService.getTotalEvents() + ' events detected',
                      icon: 'pi pi-shield',
                      color: 'red',
                      description: this.computeTotalScore()
                    });
                  }

                  if (classification.hasOwnProperty('PhishingClassification') &&
                    classification['PhishingClassification'] !== "NOT-Malicious General Class") {
                    this.eventService.setTotalEvents(this.eventService.getHeaderThreat() + this.eventService.getHeaderWarning() + this.eventService.getBodyThreat() + this.eventService.getBodyWarning() + this.eventService.getAttachmentThreat() + this.eventService.getAlidaThreat() + this.eventService.getMarleneThreat() + this.eventService.getMarleneWarning() + this.eventService.getWhoIsWarning() + this.eventService.getCertificateWarning() + this.eventService.getSnapshotWarning());
                    this.eventService.setMarleneDescriptionMalicious('<p>Marlene Analysis: classified as Maliciuos;');
                    //this.dataSharingService.marleneMalicius.next(true);
                    //this.eventService[1].status = 'Body Analysis: ' + this.bodyThreat + ' Threat, ' + this.bodyWarning + ' Warning';
                    //this.eventService[1].icon = 'pi pi-exclamation-circle';
                    this.eventService.setMarleneThreat(1);
                    this.eventService.updateEvent(1, {
                      status: 'Body Analysis: ' + (this.eventService.getBodyThreat() + this.eventService.getAlidaThreat() + this.eventService.getMarleneThreat()) + ' Threat, ' + (this.eventService.getBodyWarning() + this.eventService.getMarleneWarning() + this.eventService.getWhoIsWarning() + this.eventService.getCertificateWarning() + this.eventService.getSnapshotWarning()) + ' Warning',
                      icon: 'pi pi-exclamation-triangle',
                      color: 'red',
                      description: this.eventService.getAlidaDescription() + this.eventService.getMarleneDescriptionMalicious() + this.eventService.getMarleneDescriptionPolicy() + this.eventService.getUrlsMalicious() + this.eventService.getWhoIsDescription() + this.eventService.getCertificateDescription() + this.eventService.getSnapshotDescription()
                    });
                    this.eventService.updateEvent(3, {
                      status: 'Status: ' + this.eventService.getTotalEvents() + ' events detected',
                      icon: 'pi pi-shield',
                      color: 'red',
                      description: this.computeTotalScore()
                    });
                  }

                }
              }
            }
          }
        });

      // alida
      this.dataSharingService.textAnalysis
        .subscribe((analysis) => {
          if (analysis !== null) {
            this.analysis1 = analysis;
            if (this.analysis1.alidaResponse?.outputs?.prediction) {
              if (this.scaleAndRound(this.analysis1.alidaResponse?.outputs?.prediction[0][0]) >= 70) {
                this.eventService.setTotalEvents(this.eventService.getHeaderThreat() + this.eventService.getHeaderWarning() + this.eventService.getBodyThreat() + this.eventService.getBodyWarning() + this.eventService.getAttachmentThreat() + this.eventService.getAlidaThreat() + this.eventService.getMarleneThreat() + this.eventService.getMarleneWarning() + this.eventService.getWhoIsWarning() + this.eventService.getCertificateWarning() + this.eventService.getSnapshotWarning());
                this.eventService.setAlidaDescription('<p>NLP Analysis: High phishing probabilities;');
                //this.dataSharingService.alidaWarning.next(true);
                this.eventService.setAlidaThreat(1);
                this.eventService.updateEvent(1, {
                  status: 'Body Analysis: ' + (this.eventService.getBodyThreat() + this.eventService.getAlidaThreat() + this.eventService.getMarleneThreat()) + ' Threat, ' + (this.eventService.getBodyWarning() + this.eventService.getMarleneWarning() + this.eventService.getWhoIsWarning() + this.eventService.getCertificateWarning() + this.eventService.getSnapshotWarning()) + ' Warning',
                  icon: 'pi pi-exclamation-triangle',
                  color: 'red',
                  description: this.eventService.getAlidaDescription() + this.eventService.getMarleneDescriptionMalicious() + this.eventService.getMarleneDescriptionPolicy() + this.eventService.getUrlsMalicious() + this.eventService.getWhoIsDescription() + this.eventService.getCertificateDescription() + this.eventService.getSnapshotDescription()
                });
                this.eventService.updateEvent(3, {
                  status: 'Status: ' + this.eventService.getTotalEvents() + ' events detected',
                  icon: 'pi pi-shield',
                  color: 'red',
                  description: this.computeTotalScore()
                });
              }
            }
          }
        });

      // url
      this.dataSharingService.urlAnalysis
        .subscribe((urlAnalysis) => {
          if (urlAnalysis !== null) {
            // Update the analysis only when it's not null
            this.analysis3 = urlAnalysis;
            //count the number of blacklisted urls
            let count = 0;
            this.analysis3.forEach((url) => {
              if (url.references && url.references.length > 0) {
                count++;
              }
            });
            //this.dataSharingService.urlsBlocklisted.next(count);
            this.eventService.setBodyThreat(count);
            if (count > 0) {
              this.eventService.setTotalEvents(this.eventService.getHeaderThreat() + this.eventService.getHeaderWarning() + this.eventService.getBodyThreat() + this.eventService.getBodyWarning() + this.eventService.getAttachmentThreat() + this.eventService.getAlidaThreat() + this.eventService.getMarleneThreat() + this.eventService.getMarleneWarning() + this.eventService.getWhoIsWarning() + this.eventService.getCertificateWarning() + this.eventService.getSnapshotWarning());
              this.eventService.setUrlsMalicious('<p>Body contains blacklisted URLs;');
              this.eventService.updateEvent(1, {
                status: 'Body Analysis: ' + (this.eventService.getBodyThreat() + this.eventService.getAlidaThreat() + this.eventService.getMarleneThreat()) + ' Threat, ' + (this.eventService.getBodyWarning() + this.eventService.getMarleneWarning() + this.eventService.getWhoIsWarning() + this.eventService.getCertificateWarning() + this.eventService.getSnapshotWarning()) + ' Warning',
                icon: 'pi pi-exclamation-triangle',
                color: 'red',
                description: this.eventService.getAlidaDescription() + this.eventService.getMarleneDescriptionMalicious() + this.eventService.getMarleneDescriptionPolicy() + this.eventService.getUrlsMalicious() + this.eventService.getWhoIsDescription() + this.eventService.getCertificateDescription() + this.eventService.getSnapshotDescription()
              });
              this.eventService.updateEvent(3, {
                status: 'Status: ' + this.eventService.getTotalEvents() + ' events detected',
                icon: 'pi pi-shield',
                color: 'red',
                description: this.computeTotalScore()
              });
            }
          }
        });

      // malware
      this.dataSharingService.malwareAnalysis
        .subscribe((malwareAnalysis) => {
          if (malwareAnalysis !== null) {
            // Update the analysis only when it's not null
            this.analysis2 = malwareAnalysis;

            // check if some attachment is malicious
            let count = 0;
            this.analysis2.attachments?.forEach((attachment) => {
              if (attachment.clamAVScan !== 'OK') {
                count++;
              }
            });
            //this.dataSharingService.malwareCount.next(count);
            this.eventService.setAttachmentThreat(count);
            if (count > 0) {
              this.eventService.setTotalEvents(this.eventService.getHeaderThreat() + this.eventService.getHeaderWarning() + this.eventService.getBodyThreat() + this.eventService.getBodyWarning() + this.eventService.getAttachmentThreat() + this.eventService.getAlidaThreat() + this.eventService.getMarleneThreat() + this.eventService.getMarleneWarning() + this.eventService.getWhoIsWarning() + this.eventService.getCertificateWarning() + this.eventService.getSnapshotWarning());
              this.eventService.setFileMalicious('<p>Attachments contains malicious files;');
              this.eventService.updateEvent(2, {
                status: 'Attachment Analysis: ' + this.eventService.getAttachmentThreat() + ' Threat ',
                icon: 'pi pi-exclamation-triangle',
                color: 'red',
                description: this.eventService.getFileMalicious()
              });
              this.eventService.updateEvent(3, {
                status: 'Status: ' + this.eventService.getTotalEvents() + ' events detected',
                icon: 'pi pi-shield',
                color: 'red',
                description: this.computeTotalScore()
              });
            }
          }
        });

    }

  }

  computeTotalScore(): string {

    /* 
    
    Header:
      auth --> getHeaderWarning
      hops --> getHeaderThreat
    Body:
      whois --> getWhoIsWarning
      certificates --> getCertificateWarning
      snapshot --> getSnapshotWarning
      marlene Malicious --> getMarleneThreat
      marlene Policy --> getMarleneWarning
      alida --> getAlidaThreat
      url --> getBodyThreat
    Attachment:
      malware --> getAttachmentThreat

      LOW
      auth --> getHeaderWarning
      marlene Policy --> getMarleneWarning
      whois --> getWhoIsWarning
      snapshot --> getSnapshotWarning

      MEDIUM
      hops --> getHeaderThreat
      certificates --> getCertificateWarning

      HIGH
      malware --> getAttachmentThreat
      url --> getBodyThreat
      alida --> getAlidaThreat
      marlene Malicious --> getMarleneThreat

    */

    /*     if (this.eventService.getTotalEvents() === 0) {
          return 'No events detected';
        } */


    if (this.eventService.getAttachmentThreat() > 0 || this.eventService.getBodyThreat() > 0 || this.eventService.getAlidaThreat() > 0 || this.eventService.getMarleneThreat() > 0) {
      return 'High phishing probabilities';
    }

    if (this.eventService.getHeaderThreat() > 0 || this.eventService.getCertificateWarning() > 0) {
      return 'Moderate phishing probabilities';
    }

    if (this.eventService.getHeaderWarning() > 0 || this.eventService.getMarleneWarning() > 0 || this.eventService.getWhoIsWarning() > 0 || this.eventService.getSnapshotWarning() > 0) {
      return 'Low phishing probabilities';
    }

    return 'No events detected';
  }

  labelFinalScore(): Map<string, string> {
    //make map variable that has the score and the severity
    const severityMap = new Map<string, string>();

    if (this.computeTotalScore() === 'High phishing probabilities') {
      //return 'High';
      severityMap.set('High', 'danger');
    }
    if (this.computeTotalScore() === 'Moderate phishing probabilities') {
      //return 'Medium';
      severityMap.set('Medium', 'warning');
    }
    if (this.computeTotalScore() === 'Low phishing probabilities') {
      //return 'Low';
      severityMap.set('Low', 'warning');
    }
    //return 'Pass';
    severityMap.set('Pass', 'success');

    return severityMap;
  }


  sendReport() {
    // Assuming you have a method to get the JSON data, replace this with your logic
    const reportData = this.getReportData();

    this.sendingReport = true;

    // Call the API to create the report
    this.reportControllerService.createReport(reportData).subscribe({
      next: (response: any) => {
        // Handle the response from the API
        this.sendingReport = false;
        this.messageService.add({ severity: 'success', summary: 'Report sent', detail: 'Report sent successfully' });
        this.reportSent = true;
        //update reportSent datasharing
        this.dataSharingService.reportSent.next(true);
        console.log('Response:', response);
        this.reportUID = response;
        //update reportUID datasharing
        this.dataSharingService.reportUID.next(this.reportUID);
      },
      error: (error: any) => {
        // Handle errors from the API
        this.sendingReport = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to send report' });
        console.error('Error sending report:', error);
      }
    });
  }

  private getReportData(): any {

    return {
      userId: this.userProfile?.sub,
      addDate: new Date(),
      'HeaderAnalysis': {
        'Summary': this.dataSharingService.summary.getValue(),
        'Hops': this.dataSharingService.hops.getValue(),
        'Authentication': this.dataSharingService.auth.getValue(),
        'HeaderRaw': this.dataSharingService.headersRaw.getValue()
      },
      'BodyAnalysis': {
        'TextAnalysis': this.dataSharingService.textAnalysis.getValue(),
        'TextAnalysisSyn': this.dataSharingService.textAnalysisSyn.getValue(),
        //'Marlene': marleneValues,
        'Marlene': this.transformDataForMongoDB(this.dataSharingService.marleneAnalysis.getValue()),
        'UrlAnalysis': this.dataSharingService.urlAnalysis.getValue(),
        'BodyRaw': this.dataSharingService.bodyRaw.getValue()
      },
      'MalwareAnalysis': this.dataSharingService.malwareAnalysis.getValue(),
      'Likelihood': {
        "score": this.labelFinalScore().keys().next().value,
        "severity": this.labelFinalScore().values().next().value
      }
    };
  }

  transformDataForMongoDB(originalData: any): any {
    // Initialize transformedData with an empty results object
    const transformedData: {
      results: { [key: string]: any[] };
    } = {
      results: {}
    };

    // Check if originalData and originalData.results are defined
    if (originalData && originalData.results) {
      Object.keys(originalData.results).forEach((key, index) => {
        // Use a placeholder key
        const placeholderKey = `placeholder_key_${index}`;
        transformedData.results[placeholderKey] = originalData.results[key];
      });
    } else {
      console.error('originalData or originalData.results is undefined or null');
      // Handle the case where originalData.results is undefined or null
      // For example, you might want to return an empty structure, log an error, or throw an exception
    }

    return transformedData;
  }

  getUID(response: any): string {
    try {
      return response.UID || '';
    } catch (error) {
      console.error('Error extracting UID:', error);
      return '';
    }
  }

/*   getPageLocation(): string {
    return window.location.href.replace("report", "get-report/");
  } */

  getPageLocation(): string {
    let currentUrl = window.location.href;
    let baseUrl = currentUrl.split('#')[0]; // Split to get the base URL before '#'
    let hash = currentUrl.split('#')[1];
  
    if (hash) {
      // Removing any unwanted query parameters specifically
      let params = hash.split('&');
      let filteredParams = params.filter(param => 
        !param.startsWith('state') &&
        !param.startsWith('session_state') && 
        !param.startsWith('iss') &&
        !param.startsWith('code') // Add any other parameters you want to filter out
      );
  
      let cleanHash = filteredParams.join('&'); // Rejoin the remaining parameters
      return `${baseUrl.replace("report", "get-report/")}` + cleanHash; // Rebuild the URL with the desired path change
    }
    return baseUrl; // Return the base URL if no hash part is present
  }
  
  

  scaleAndRound(value: number): number {
    return Math.round(value * 100);
  }

  differenceInDays(date: string): number {
    return Math.floor((new Date().getTime() - new Date(date).getTime()) / (1000 * 3600 * 24));
  }

  // function to copy the result getPageLocation() + getUID(reportUID)
  async copyToClipboard(reportResponse: any) {
    let baseUrl = this.getPageLocation();
    let uid = this.getUID(reportResponse);  // Assuming you pass the appropriate response object
  
    // Clean the URL by removing the access token and append the UID
    let finalUrl = baseUrl + uid;  // Appending the UID directly
  
    try {
      await navigator.clipboard.writeText(finalUrl);
      this.snackBar.open('Report Url copied!', 'Ok', { duration: 2000 });
    } catch (err) {
      console.error('Failed to copy: ', err);
      this.snackBar.open('Failed to copy URL.', 'Ok', { duration: 2000 });
    }
  }
  

}
