import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'ui-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  @Input() customLabels: string[] = [];
  items: MenuItem[] | undefined;
  home: MenuItem | undefined;

  ngOnInit(): void {
    //this.items = this.customLabels.map(label => ({ label }));

    this.items = this.customLabels.map((label, index) => {
      if (label === 'Report History') {
        return { label, routerLink: '/report-history' }; // Assuming '/report-history' is the route you want to navigate to
      } else {
        return { label };
      }
    });

    this.home = { icon: 'pi pi-home', routerLink: '/' };
  }

}
