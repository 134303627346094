import { Component, OnInit } from '@angular/core';
import { KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss']
})
export class UserAccountComponent implements OnInit {
  userProfile: KeycloakTokenParsed  | undefined;

  
  constructor(private keycloakService: KeycloakService) {}

  async ngOnInit() {
    if (this.keycloakService.isLoggedIn()) {
      this.userProfile = this.keycloakService.getKeycloakInstance().idTokenParsed;
      //get user id from keycloack service
/*       this.keycloakService.getKeycloakInstance().loadUserProfile().then((profile: KeycloakProfile) => {
        console.log('User profile', profile);
      }); */
    }
  }
}
