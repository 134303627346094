<div class="">
    <div class="mb-4">
        <h2>Image Analyzer:</h2>
        <span class="text-gray-400">Scan Email for check if image hide sometching</span>
    </div>

    <div class="flex gap-8">
        <div class="pb-8">
            <!-- IMAGE RENDER -->
            <img class="bg-white border border-gray-400 rounded-lg" alt="qr-code" width="200" src="assets/qrcode.png">
        </div>

        <!-- IMAGE ANALYSIS -->
        <div class="flex-auto lg:pr-8 pb-8">
            <div class="px-8 py-8 bg-white border border-gray-400 rounded-lg break-all">
                <div>
                   <h2>Image Info</h2>
                   <p><b>File Name: </b><code>image.jpg</code></p>
                   <p><b>Size: </b><code>142.7</code><code> Kb</code></p>
                   <br>
                   <mat-divider></mat-divider>
                   <h2 class="pt-8">Url Analysis</h2>
                   <p><b>url: </b><code> https://cyberseas.eu/ </code></p>
                   <p><b>PhishTank: </b>&ensp;&ensp;&ensp;<code>OK - Site is not blacklisted</code></p>
                   <br>
                   <mat-divider></mat-divider>
                   <h2 class="pt-8">Threat Analysys</h2>
                   <p><b>md5: </b><code>4e3ddfa69819c44746cde221d2f0b4c5</code></p>
                   <p><b>sha1: </b><code>ea70bff87858a91d111c6e9c21703fa04540ec6a</code></p>
                   <p><b>sha256: </b><code>268483dc729d04f2569c7ba26502c6ddf0d3ebe24bfaeee1947b815a5055829e</code></p>
                   <p><b>Entropy: </b><code>7.994379958122041</code></p>
                   <p><b>clamAVScan: </b><code>OK</code></p>
               </div>
           </div>

        </div>
    </div>
</div>