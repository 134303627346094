<h2>Ursl Snapshot</h2>

<div *ngIf="analysis.length < 1 && !spinner;" class="pl-4 fade-in-left">
    No Urls Found in the body of the email.
</div>

<div *ngIf="spinner; else snapshotBody" class="pl-4">
    <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i> LOADING...
</div>

<ng-template #snapshotBody>

    <div *ngFor="let url of orderArray(analysis, 'url')" class="grid grid-cols-1 break-words fade-in-down">

        <div>
            <b>URL: </b> <span class="text-xs">{{url.url}}</span> <br>

            <span *ngIf="url.snapshots && (url.snapshots.length > 0); else redSnapshot" class="">
                Found {{url.snapshots.length}} Snapshots
            </span>

            <ng-template #redSnapshot>
                <div class="flex">
                    <mat-icon mat-list-icon class="warning">warning</mat-icon>
                    <span class="text-red-600">Found {{url.snapshots?.length}} Snapshots</span>
                </div>
            </ng-template>

        </div>

        <div *ngIf="url.snapshots && url.snapshots.length > 0">

            <div class=" mt-2">
                <details>
                    <summary>Snapshots</summary>
                    <div class="col-span-1 text-gray-300 rounded-lg p-8 fade-in-left"
                        style="background-color: rgba(40, 43, 46)">
                        <code>
<!-- <pre class="text-xs overflow-auto pb-4"> -->
<pre class="text-xs overflow-auto">
<div *ngFor="let snapshot of url.snapshots let i=index">
{{snapshot.timeStamp}}
{{snapshot.snapshotUrl}}
</div>
</pre>
                </code>
                    </div>
                </details>
            </div>

        </div>

        <p-divider class="block lg:hidden mt-2"></p-divider>
        <p-divider class="hidden lg:block mt-4"></p-divider>

    </div>

</ng-template>