import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Message, MessageService } from 'primeng/api';
import { MalwareAnalyzerApiControllerService, MalwareAnalysis } from '@phishbowl/malware-analyzer-client';
import { DataSharingService } from '../../data-sharing/data-sharing.service';
import { filter, mergeMap, Observable, share, take } from 'rxjs';


@Component({
  selector: 'ui-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss',
    '../../style.scss'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AttachmentsComponent implements OnInit, AfterViewInit {

  uploadedFiles: any[] = [];
  scannedFiles: any[] = [];
  analysis: MalwareAnalysis = {};
  msgs!: Message[];
  spinner: boolean = true;

  constructor(
    private dataSharingService: DataSharingService,
    private malwareAnalyzerApiControllerService: MalwareAnalyzerApiControllerService
  ) { }

  ngAfterViewInit(): void { }

  ngOnInit(): void {

    this.dataSharingService.malwareAnalysisLoadind
      .subscribe((loading) => {
        // Use the loading state to display/hide the loading spinner
        this.spinner = true;
      });

    this.dataSharingService.malwareAnalysis
      .subscribe((malwareAnalysis) => {
        if (malwareAnalysis !== null) {
          // Update the analysis only when it's not null
          this.analysis = malwareAnalysis;

          // check if some attachment is malicious
/*           let count = 0;
          this.analysis.attachments?.forEach((attachment) => {
            if (attachment.clamAVScan !== 'OK') {
              count++;
            }
          }); */
          //this.dataSharingService.malwareCount.next(count);
          this.spinner = false;
        } else {
          // Handle the case when marleneAnalysis is null (e.g., show a placeholder) Or any appropriate default value
          this.analysis = {};
        }
      });


  }

  sanitizeFilename(name: String) {
    return name.replace("[:\\\\/*?|<> \"]", "_");
  }

  reverse(array: any) {
    return array.reverse();
  }

  bytesToMegaBytes(bytes: number) {
    return bytes / 1000000;
  }

  bytesToKiloBytes(bytes: number) {
    return bytes / 1000;
  }

}
