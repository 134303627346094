<ui-breadcrumbs (click)="revealEasterEgg()" *ngIf="uid" [customLabels]="['Report History', uid ]"></ui-breadcrumbs>

<div *ngIf="loading"> <!-- Show spinner when loading is true -->
    <!-- Add your spinner or loading animation here -->
    <!-- Loading... -->
</div>

<div *ngIf="!loading">

    <div class="fade-in-down">
        <div *ngIf="reportFound else notFound">

            <div class="drop-shadow-lg grid grid-cols-1 mx-4 md:mx-16 mb-16 rounded-lg bg-white">
                <div class="col-span-1 py-8">
                    <nav mat-tab-nav-bar class="mb-8" [tabPanel]="tabPanel">
                        <a mat-tab-link [routerLink]="['header']" routerLinkActive #header="routerLinkActive"
                            [active]="header.isActive">Header</a>
                        <a mat-tab-link [routerLink]="['body']" routerLinkActive #body="routerLinkActive"
                            [active]="body.isActive">Body</a>
                        <a mat-tab-link [routerLink]="['attach']" routerLinkActive #attachment="routerLinkActive"
                            [active]="attachment.isActive">Attachment</a>
                        <!-- <a mat-tab-link [routerLink]="['report']" routerLinkActive #attachment="routerLinkActive"
                            [active]="attachment.isActive">Report</a> -->
                    </nav>
                    <mat-tab-nav-panel #tabPanel>
                        <router-outlet></router-outlet>
                    </mat-tab-nav-panel>
                </div>
            </div>

            <details id="easterEgg" style="display: none;">
                <summary> </summary>
                <pre>{{ report | json }}</pre>
            </details>

        </div>
    </div>

    <div class="fade-in-down">
        <ng-template #notFound>
            <div id="main">
                <div class="fof">
                    <h1>404, report Not Found</h1>
                </div>
            </div>
        </ng-template>
    </div>

</div>