import { Component } from '@angular/core';

@Component({
  selector: 'phish-bowl-ui-image-analyzer',
  templateUrl: './image-analyzer.component.html',
  styleUrls: ['./image-analyzer.component.scss']
})
export class ImageAnalyzerComponent {

}
