import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DataSharingService } from '@phishbowl/shared';
import { filter, mergeMap, share, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HeaderControllerService } from '@phishbowl/header-analyzer-client';
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'ui-header-row',
  templateUrl: './header-row.component.html',
  styleUrls: ['./header-row.component.scss',
    '../../style.scss'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderRowComponent implements OnInit {
  text!: string;
  spinner: boolean = true;

  @ViewChild('area2')
  area2!: ElementRef<HTMLTextAreaElement>;

  constructor(
    private dataSharingService: DataSharingService,
    private headerControllerService: HeaderControllerService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {

    this.dataSharingService.headersRawLoadind
      .subscribe((loading) => {
        // Use the loading state to display/hide the loading spinner
        this.spinner = true;
      });

    this.dataSharingService.headersRaw
      .subscribe((headersRaw) => {
        if (headersRaw !== null) {
          // Update the analysis only when it's not null
          this.text = headersRaw;
          this.spinner = false;
        } else {
          // Handle the case when headersRaw is null (e.g., show a placeholder) Or any appropriate default value
          this.text = '';
        }
      });

  }

  copyText() {
    this.area2.nativeElement.select();
    document.execCommand('copy');
    this.area2.nativeElement.setSelectionRange(0, 0);
    this.snackBar.open('Email header copied!', 'Ok', { duration: 2000 });
  }

}
