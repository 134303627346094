<ui-breadcrumbs [customLabels]="['Reports History']"></ui-breadcrumbs>
<div class="drop-shadow-lg grid grid-cols-1 mx-4 md:mx-16 mb-16 rounded-lg bg-white fade-in-left">
    <div *ngIf="userProfile && reports" class="mx-8 my-8">
        <div *ngIf="reports.length > 0; else elseBlock">
            <p-table [value]="reports" [autoLayout]="true" [rows]="10" [showCurrentPageReport]="true" 
                [rowsPerPageOptions]="[10, 25, 50]" [paginator]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" class="w-full">
                <ng-template pTemplate="header">
                    <tr>
                        <!-- <th>_id</th> -->
                        <th>Subject</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Email Created</th>
                        <th>Analysis Date</th>
                        <th>Marlene</th>
                        <th>Alida</th>
                        <th>Likelihood</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-report>
                    <tr>
                        <!--                         <td>
                            <div class="truncate max-w-xs">{{ report._id }}</div>
                        </td> -->
                        <td>
                            <div *ngIf="report.transformedSummary.Subject" class="truncate max-w-40"
                                title="{{ report.transformedSummary.Subject }}">{{
                                report.transformedSummary.Subject }}</div>
                        </td>
                        <td>
                            <div *ngIf="report.transformedSummary.From" class="truncate max-w-40"
                                title="{{ report.transformedSummary.From }}">{{
                                report.transformedSummary.From }}</div>
                        </td>
                        <td>
                            <div *ngIf="report.transformedSummary.To" class="truncate max-w-40"
                                title="{{ report.transformedSummary.To }}">{{
                                report.transformedSummary.To }}</div>
                        </td>
                        <td>
                            <span *ngIf="report.transformedSummary.Date" class="truncate max-w-40">
                                <small>
                                    {{ report.transformedSummary.Date | date:"MMM dd, yyyy" }}
                                </small>
                            </span>
                            <span *ngIf="report.transformedSummary.Date" class="truncate max-w-40">
                                <small>
                                    {{ report.transformedSummary.Date | date:"'at' HH:mm" }}
                                </small>
                            </span>
                        </td>
                        <td>
                            <span *ngIf="report.addDate" class="truncate max-w-40">
                                <small>{{ report.addDate | date:"MMM dd, yyyy" }}</small>
                            </span>
                            <span *ngIf="report.addDate" class="truncate max-w-40">
                                <small>{{ report.addDate | date:"' at' HH:mm" }}</small>
                            </span>
                        </td>
                        <td *ngIf="report.PhishingClassification || report.PolicyViolationClassification else noMarlene">

                            <p-tag *ngIf="report.PhishingClassification"
                                [value]="report.PhishingClassification === 'NOT-Malicious General Class' ? 'Not-Malicious' : 'Malicious'"
                                [severity]="report.PhishingClassification === 'NOT-Malicious General Class' ? 'success' : 'danger'">
                            </p-tag>

                            <p-tag *ngIf="report.PolicyViolationClassification" class="ml-2"
                                [value]="report.PolicyViolationClassification === 'NOT-PolicyViolation' ? 'Not-Policy-Vio.' : 'Policy-Violaion'"
                                [severity]="report.PolicyViolationClassification === 'NOT-PolicyViolation' ? 'success' : 'warning'">
                            </p-tag>

                        </td>
                        <ng-template #noMarlene>
                            <td>
                                <p-tag [value]="'N/A'" [style]="{ 'background': 'gray'}"></p-tag>
                            </td>
                        </ng-template>
                        <td *ngIf="report.BodyAnalysis.TextAnalysis.alidaResponse; else noAlida">
                            <span *ngIf="report.BodyAnalysis.TextAnalysis.alidaResponse.outputs.prediction"
                                [ngStyle]="{'color': isAlidaPredictionMalicious(report) ? 'red' : 'green'}">
                                {{ getAlidaPredictionText(report) }}
                            </span>
                            <!-- <p-progressBar [value]="getAlidaPredictionText(report)" [showValue]="false"></p-progressBar> -->
                        </td>
                        <ng-template #noAlida>
                            <td>
                                <p-tag [value]="'N/A'" [style]="{ 'background': 'gray'}"></p-tag>
                            </td>
                        </ng-template>

                        <!-- SCORE -->
                        <td>
                            <div *ngIf="report.Likelihood; else NoScore" class="truncate max-w-40">
                                <p-tag [value]="report.Likelihood.score" id="score" [severity]="report.Likelihood.severity"></p-tag>
                            </div>
                        </td>
                        <ng-template #NoScore>
                            <td>
                                <p-tag [value]="'N/A'" [style]="{ 'background': 'gray'}"></p-tag>
                            </td>
                        </ng-template>

                        <!-- LINK -->
                        <td><i class="pi pi-external-link" (click)="openReportDetails(report._id)"
                                style="cursor: pointer;"></i></td>

                    </tr>
                </ng-template>
            </p-table>
        </div>

        <ng-template #elseBlock>
            <div class="flex flex-col items-center">
                <p class="text-2xl text-gray-500">No reports found</p>
            </div>
        </ng-template>
    </div>
</div>