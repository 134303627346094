export class Utils {

    /* order array of object */
    public orderArray(array: any[], key: string): any[] {
        return array.sort((a, b) => {
          if (a[key] < b[key]) {
            return -1;
          } else if (a[key] > b[key]) {
            return 1;
          } else {
            return 0;
          }
        });
      }

}
