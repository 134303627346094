import { Injectable } from '@angular/core';
import { Coordinate } from 'ol/coordinate';
import { BehaviorSubject, combineLatest, map, Subject } from 'rxjs';
import { URLAnalysis } from '@phishbowl/body-analyzer-client';
import { TextAnalysisSyn } from '@phishbowl/body-analyzer-client';
import { MarleneResponse } from '@phishbowl/body-analyzer-client';
import { HeaderDTO } from '@phishbowl/header-analyzer-client';
import { HopDTO } from '@phishbowl/header-analyzer-client';
import { AuthenticationDTO } from '@phishbowl/header-analyzer-client';
import { TextAnalysis } from '@phishbowl/body-analyzer-client';
import { MalwareAnalysis } from '@phishbowl/malware-analyzer-client';
import { InjectionToken } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class DataSharingService {

  readonly emlFile = new BehaviorSubject<File | null>(null);

  readonly headerAnalyzerBasePath = new BehaviorSubject<string>('');
  readonly malwareAnalyzerBasePath = new BehaviorSubject<string>('');
  readonly bodyAnalyzerBasePath = new BehaviorSubject<string>('');
  readonly sedReportBasePath = new BehaviorSubject<string>('');
  readonly emailAnonymizerBasePath = new BehaviorSubject<string>('');


  /* -------------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------------- */
  /* OLD VARIABLES FOR MAP */

  readonly coordinates: BehaviorSubject<Coordinate[] | null> =
    new BehaviorSubject<Coordinate[] | null>(null);

  // the name of file, saved when the file is uploaded (eml), in order to check if the file has changed
  readonly name1: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  // the name of file, saved when the file changes in hops map, in order to generate new coordinates
  readonly name2: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  readonly lon: BehaviorSubject<number | null> =
    new BehaviorSubject<number | null>(null);

  readonly lat: BehaviorSubject<number | null> =
    new BehaviorSubject<number | null>(null);


  /* -------------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------------- */


  /*
  *   ┌┬┐┌─┐┬─┐┬  ┌─┐┌┐┌┌─┐
  *   │││├─┤├┬┘│  ├┤ │││├┤ 
  *   ┴ ┴┴ ┴┴└─┴─┘└─┘┘└┘└─┘
  */
  readonly marleneLoading = new BehaviorSubject<boolean>(false);
  readonly marleneAnalysis = new BehaviorSubject<MarleneResponse | null>(null);
  //readonly marleneMalicius = new BehaviorSubject<boolean>(false);
  //readonly marlenePolicy = new BehaviorSubject<boolean>(false);


  updateMarleneLoading(value: boolean) {
    this.marleneLoading.next(value);
  }
  updateMarleneAnalysis(value: MarleneResponse | null) {
    this.marleneAnalysis.next(value);
  }
  /* -------------------------------------------------------------------------------- */

  /*   
  *   ┌─┐┬ ┬┌┬┐┌┬┐┌─┐┬─┐┬ ┬
  *   └─┐│ │││││││├─┤├┬┘└┬┘
  *   └─┘└─┘┴ ┴┴ ┴┴ ┴┴└─ ┴ 
  */
  readonly summaryLoading = new BehaviorSubject<boolean>(false);
  readonly summary = new BehaviorSubject<HeaderDTO[] | null>(null);

  updateSummaryLoading(value: boolean) {
    this.summaryLoading.next(value);
  }
  updateSummary(value: HeaderDTO[] | null) {
    this.summary.next(value);
  }
  /* -------------------------------------------------------------------------------- */


  /* 
  *   ┬ ┬┌─┐┌─┐┌─┐
  *   ├─┤│ │├─┘└─┐
  *   ┴ ┴└─┘┴  └─┘
  */
  readonly hopsLoading = new BehaviorSubject<boolean>(false);
  readonly hops = new BehaviorSubject<HopDTO[] | null>(null);
  readonly fromIp = new BehaviorSubject<string[][] | null>(null);
  readonly byIp = new BehaviorSubject<string[][] | null>(null);
  //readonly hopsDelay = new BehaviorSubject<number>(0);
  //readonly hopsDelayObservable = this.hopsDelay.asObservable();

  updateHopsLoading(value: boolean) {
    this.hopsLoading.next(value);
  }
  updateHops(value: HopDTO[] | null) {
    this.hops.next(value);
  }
  updateFromIp(value: string[][] | null) {
    this.fromIp.next(value);
  }
  updateByIp(value: string[][] | null) {
    this.byIp.next(value);
  }
  /* -------------------------------------------------------------------------------- */


  /*
  *   ┌─┐┬ ┬┌┬┐┬ ┬
  *   ├─┤│ │ │ ├─┤
  *   ┴ ┴└─┘ ┴ ┴ ┴
  */
  readonly authLoading = new BehaviorSubject<boolean>(false);
  readonly auth = new BehaviorSubject<AuthenticationDTO | null>(null);
  //readonly authFailed = new BehaviorSubject<number>(0);

  updateAuthLoading(value: boolean) {
    this.authLoading.next(value);
  }
  updateAuth(value: AuthenticationDTO | null) {
    this.auth.next(value);
  }
  /* -------------------------------------------------------------------------------- */


  /*
  *   ┬ ┬┌─┐┌─┐┌┬┐┌─┐┬─┐┌─┐  ┬─┐┌─┐┬ ┬
  *   ├─┤├┤ ├─┤ ││├┤ ├┬┘└─┐  ├┬┘├─┤│││
  *   ┴ ┴└─┘┴ ┴─┴┘└─┘┴└─└─┘  ┴└─┴ ┴└┴┘
  */
  readonly headersRawLoadind = new BehaviorSubject<boolean>(false);
  readonly headersRaw = new BehaviorSubject<string | null>(null);

  updateHeadersRawLoading(value: boolean) {
    this.headersRawLoadind.next(value);
  }
  updateHeadersRaw(value: string | null) {
    this.headersRaw.next(value);
  }
  /* -------------------------------------------------------------------------------- */


  /*
  *   ┌┬┐┌─┐─┐ ┬┌┬┐  ┌─┐┌┐┌┌─┐┬ ┬ ┬┌─┐┬┌─┐
  *    │ ├┤ ┌┴┬┘ │   ├─┤│││├─┤│ └┬┘└─┐│└─┐
  *    ┴ └─┘┴ └─ ┴   ┴ ┴┘└┘┴ ┴┴─┘┴ └─┘┴└─┘
  */
  readonly textAnalysisLoadind = new BehaviorSubject<boolean>(false);
  readonly textAnalysis = new BehaviorSubject<TextAnalysis | null>(null);
  //alida > 70%
  //readonly alidaWarning = new BehaviorSubject<boolean>(false);
  // whois
 //readonly whoIsThreat = new BehaviorSubject<number>(0);
  // certificates
  //readonly certificatesThreat = new BehaviorSubject<number>(0);
  // time machine
  //readonly snapshotThreat = new BehaviorSubject<number>(0);

  updateTextAnalysisLoading(value: boolean) {
    this.textAnalysisLoadind.next(value);
  }
  updateTextAnalysis(value: TextAnalysis | null) {
    this.textAnalysis.next(value);
  }
  /* -------------------------------------------------------------------------------- */


  /*
  *   ┌─┐┬ ┬┌┐┌
  *   └─┐└┬┘│││
  *   └─┘ ┴ ┘└┘
  */
  readonly textAnalysisSynLoadind = new BehaviorSubject<boolean>(false);
  readonly textAnalysisSyn = new BehaviorSubject<TextAnalysisSyn | null>(null);

  updateTextAnalysisSynLoading(value: boolean) {
    this.textAnalysisSynLoadind.next(value);
  }
  updateTextAnalysisSyn(value: TextAnalysisSyn | null) {
    this.textAnalysisSyn.next(value);
  }
  /* -------------------------------------------------------------------------------- */


  /*
  *   ┌┐ ┌─┐┌┬┐┬ ┬  ┬─┐┌─┐┬ ┬
  *   ├┴┐│ │ ││└┬┘  ├┬┘├─┤│││
  *   └─┘└─┘─┴┘ ┴   ┴└─┴ ┴└┴┘
 */
  readonly bodyRawLoadind = new BehaviorSubject<boolean>(false);
  readonly bodyRaw = new BehaviorSubject<string | null>(null);

  updateBodyRawLoading(value: boolean) {
    this.bodyRawLoadind.next(value);
  }
  updateBodyRaw(value: string | null) {
    this.bodyRaw.next(value);
  }
  /* -------------------------------------------------------------------------------- */


  /*
  *   ┬ ┬┬─┐┬    ┌─┐┌┐┌┌─┐┬ ┬ ┬┌─┐┬┌─┐
  *   │ │├┬┘│    ├─┤│││├─┤│ └┬┘└─┐│└─┐
  *   └─┘┴└─┴─┘  ┴ ┴┘└┘┴ ┴┴─┘┴ └─┘┴└─┘
  */
  readonly urlAnalysisLoadind = new BehaviorSubject<boolean>(false);
  readonly urlAnalysis = new BehaviorSubject<URLAnalysis[] | null>(null);
  //readonly urlsBlocklisted = new BehaviorSubject<number>(0);

  updateUrlAnalysisLoading(value: boolean) {
    this.urlAnalysisLoadind.next(value);
  }
  updateUrlAnalysis(value: URLAnalysis[] | null) {
    this.urlAnalysis.next(value);
  }
  /* -------------------------------------------------------------------------------- */


  /*
  *   ┌┬┐┌─┐┬  ┬ ┬┌─┐┬─┐┌─┐
  *   │││├─┤│  │││├─┤├┬┘├┤ 
  *   ┴ ┴┴ ┴┴─┘└┴┘┴ ┴┴└─└─┘
  */
  readonly malwareAnalysisLoadind = new BehaviorSubject<boolean>(false);
  readonly malwareAnalysis = new BehaviorSubject<MalwareAnalysis | null>(null);
  //readonly malwareCount = new BehaviorSubject<number>(0);


  updateMalwareAnalysisLoading(value: boolean) {
    this.malwareAnalysisLoadind.next(value);
  }
  updateMalwareAnalysis(value: MalwareAnalysis | null) {
    this.malwareAnalysis.next(value);
  }
  /* -------------------------------------------------------------------------------- */

  /* 
  *      __                ___             ___                    __ 
  *     / /  ___  ___ ____/ (_)__  ___ _  / _ \___ ___  ___  ____/ /_
  *    / /__/ _ \/ _ `/ _  / / _ \/ _ `/ / , _/ -_) _ \/ _ \/ __/ __/
  *   /____/\___/\_,_/\_,_/_/_//_/\_, / /_/|_|\__/ .__/\___/_/  \__/ 
  *                              /___/          /_/                  
  */

  readonly overallLoadingSubject = new BehaviorSubject<boolean>(false);
  readonly reportSent = new BehaviorSubject<boolean>(false);
  readonly reportUID = new BehaviorSubject<string | null>(null);

  // Method to update overall loading variable
  updateOverallLoading(loading: boolean): void {
    this.overallLoadingSubject.next(loading);
  }  

  // Method to update report sent variable
  updateReportSent(sent: boolean): void {
    this.reportSent.next(sent);
  }

  // Method to update report UID variable
  updateReportUID(uid: string | null): void {
    this.reportUID.next(uid);
  }

}

export const EMAIL_ANONYMIZER_BASE_PATH = new InjectionToken<string>('EMAIL_ANONYMIZER_BASE_PATH');
