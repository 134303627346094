import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DataSharingService } from '@phishbowl/shared';
import { TextControllerService, TextAnalysisSyn } from '@phishbowl/body-analyzer-client';
import { Observable, filter, finalize, mergeMap, share, take, Subscription } from 'rxjs';

@Component({
  selector: 'ui-text-analysis-syn',
  templateUrl: './text-analysis-syn.component.html',
  styleUrls: ['./text-analysis-syn.component.scss']
})
export class TextAnalysisSynComponent implements OnInit, OnDestroy {

  value: number = 0;
  radarChartOptions: any;
  sentimentAnalysisData: any;
  analysis: TextAnalysisSyn = {};
  labels: String[] = [];
  scores: number[] = [];
  spinner: boolean = true;
  subscriptions: Subscription[] = [];

  constructor(
    public dataSharingService: DataSharingService,
    private textControllerService2: TextControllerService
  ) { }


  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }


  ngOnInit(): void {

    /*     const emlFile = this.dataSharingService.emlFile.getValue();
        this.dataSharingService.textAnalyzerSynLoading.next(true);
        this.subscriptions = []; */



    this.dataSharingService.textAnalysisSynLoadind
      .subscribe((loading) => {
        // Use the loading state to display/hide the loading spinner
        this.spinner = true;
      });

    this.dataSharingService.textAnalysisSyn
      .subscribe((analysis) => {
        if (analysis !== null) {
          // Update the analysis only when it's not null
          this.analysis = analysis;

          /* LOGIC */

          if (this.analysis.phishingResponse) {
            this.value = this.scaleAndRound(this.analysis.phishingResponse![0].phishing!);
          }

          //put labels and score from sentimentResponse into array
          this.labels = []; //clear array
          this.scores = []; //clear array

          if (this.analysis.sentimentResponse) {
            for (const sentiment of this.analysis.sentimentResponse) {
              if (sentiment.sentiment) {
                for (const emoRoBERTaOutput of sentiment.sentiment[0]) {
                  if (emoRoBERTaOutput.label && emoRoBERTaOutput.score) {
                    this.labels.push(emoRoBERTaOutput.label);
                    this.scores.push(emoRoBERTaOutput.score);
                  }
                }
              }
            }
          }

          this.sentimentAnalysisData = {
            labels: this.labels,
            datasets: [
              {
                label: 'Sentiment Analysis',
                backgroundColor: 'rgba(179,181,198,0.2)',
                borderColor: 'rgba(179,181,198,1)',
                pointBackgroundColor: 'rgba(179,181,198,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(179,181,198,1)',
                data: this.scores,
              },
            ],
          };

          this.radarChartOptions = {
            plugins: {
              legend: {
                display: false,
              },
            },
          }

          /* END LOGIC */

          this.spinner = false;
        } else {
          // Handle the case when marleneAnalysis is null (e.g., show a placeholder) Or any appropriate default value
          this.analysis = {};
        }
      });



/*     if (emlFile != null) {
      //if (this.dataSharingService.name1.getValue() != this.dataSharingService.name2.getValue()) {
      //this.dataSharingService.name2.next(this.dataSharingService.name1.getValue());
      this.subscriptions.push(
        this.textControllerService2
          .textAnalysisSyn(emlFile)
          .pipe(
            take(1),
            finalize(() => this.dataSharingService.textAnalyzerSynLoading.next(false)),
          )
          .subscribe((textAnalysisSyn) => {
            this.spinner = true;
            this.analysis = textAnalysisSyn;
            this.spinner = false;


            if (this.analysis.phishingResponse) {
              this.value = this.scaleAndRound(this.analysis.phishingResponse![0].phishing!);
            }

            //put labels and score from sentimentResponse into array
            this.labels = []; //clear array
            this.scores = []; //clear array

            if (this.analysis.sentimentResponse) {
              for (const sentiment of this.analysis.sentimentResponse) {
                if (sentiment.sentiment) {
                  for (const emoRoBERTaOutput of sentiment.sentiment[0]) {
                    if (emoRoBERTaOutput.label && emoRoBERTaOutput.score) {
                      this.labels.push(emoRoBERTaOutput.label);
                      this.scores.push(emoRoBERTaOutput.score);
                    }
                  }
                }
              }
            }


            this.spinner = false;

            this.sentimentAnalysisData = {
              labels: this.labels,
              datasets: [
                {
                  label: 'Sentiment Analysis',
                  backgroundColor: 'rgba(179,181,198,0.2)',
                  borderColor: 'rgba(179,181,198,1)',
                  pointBackgroundColor: 'rgba(179,181,198,1)',
                  pointBorderColor: '#fff',
                  pointHoverBackgroundColor: '#fff',
                  pointHoverBorderColor: 'rgba(179,181,198,1)',
                  data: this.scores,
                },
              ],
            };

          }));
      //}
    }

    const file$: Observable<File> = this.dataSharingService.emlFile
      .pipe(filter((file): file is File => !!file))
      .pipe(share());


    //add if to check if file name is different from previous one

    this.subscriptions.push(
      file$
        .pipe(
          //filter((file) => this.dataSharingService.name1.getValue() !== this.dataSharingService.name2.getValue()), // Check if the file name is different
          mergeMap((file) =>
            this.textControllerService2.textAnalysisSyn(file).pipe(
              take(1),
              finalize(() => this.dataSharingService.textAnalyzerSynLoading.next(false))
            )))
        .subscribe((textAnalysisSyn) => {
          this.spinner = true;
          this.analysis = textAnalysisSyn;
          this.spinner = false;


          if (this.analysis.phishingResponse)
            this.value = this.scaleAndRound(this.analysis.phishingResponse![0].phishing!);

          //put labels and score from emoroberta into array
          this.labels = []; //clear array
          this.scores = []; //clear array

          if (this.analysis.sentimentResponse) {
            for (const sentiment of this.analysis.sentimentResponse) {
              if (sentiment.sentiment) {
                for (const emoRoBERTaOutput of sentiment.sentiment[0]) {
                  if (emoRoBERTaOutput.label && emoRoBERTaOutput.score) {
                    this.labels.push(emoRoBERTaOutput.label);
                    this.scores.push(emoRoBERTaOutput.score);
                  }
                }
              }
            }
          }

          this.spinner = false;

          this.sentimentAnalysisData = {
            labels: this.labels,
            datasets: [
              {
                label: 'Sentiment Analysis',
                backgroundColor: 'rgba(179,181,198,0.2)',
                borderColor: 'rgba(179,181,198,1)',
                pointBackgroundColor: 'rgba(179,181,198,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(179,181,198,1)',
                data: this.scores,
              },
            ],
          };

        }));

    this.radarChartOptions = {
      plugins: {
        legend: {
          display: false,
        },
      },
    } */


  }

  scaleAndRound(value: number): number {
    return Math.round(value * 100);
  }

}
