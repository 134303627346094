<mat-toolbar color="primary">
    <div class="logo-and-title">
        <img class="logo" src="assets/bitmap.png" alt="Logo">
        <span class="title">PhishBowl</span>
    </div>
    
    <div class="nav-buttons">
        <a class="ml-8" mat-button [routerLink]="'/analyzer'"> Analyzer </a>
        
        <a class="" mat-button pBadge value="New" [routerLink]="'/###'"> ClamAV </a>
                
        <!-- <p-button label="ClamAV" badge="New" styleClass="mr-2"></p-button> -->

<!--         <button mat-icon-button [routerLink]="['']">
            <mat-icon>mail</mat-icon>
        </button> -->
    </div>
    <div class="spacer"></div>
    <button mat-icon-button>
        <mat-icon>account_circle</mat-icon>
    </button>
</mat-toolbar>

<router-outlet></router-outlet>