import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-text-analyzer',
  templateUrl: './text-analyzer.component.html',
  styleUrls: ['./text-analyzer.component.scss']
})
export class TextAnalyzerComponent implements OnInit {

  public textObfuscationNeedleValue = 65;
  public forgeryRequestNeedleValue = 45;
  public centralLabel = '';
  // public name = 'Text Obfuscation'
  public textObfuscationBottomLabel = '65';
  public forgeryRequestBottomLabel = '45';
  public options = {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    arcColors: ['rgb(61,204,91)', 'rgb(239,214,19)', 'rgb(255,84,84)'],
    arcDelimiters: [40, 60],
    rangeLabel: ['0', '100'],
    needleStartValue: 50,
  };

  grammarAnalysisData: any;
  barChartOptions: any;
  sentimentAnalysisData: any;
  radarChartOptions: any;

  constructor() { }

  ngOnInit(): void {
    this.barChartOptions = {
      //font size
/*       scales: {
        x: {
          ticks: {
            font: {
              size: 18,
            },
          },
        },
        y: {
          ticks: {
            font: {
              size: 18,
            },
          },
        },
      }, */
      plugins: {
        legend: {
          display: false,
        },
      },
    }
    this.radarChartOptions = {
      //font size
/*       scales: {
        r: {
          ticks: {
            font: {
              size: 18,
            },
          },
        },
      }, */
      plugins: {
        legend: {
          display: false,
        },
      },
    }
    this.grammarAnalysisData = {
      datasets: [
        {
          data: [2, 4, 2, 5, 8, 7, 5, 6, 8, 3, 7],
          backgroundColor: [
            '#FF8066',
            '#FF8066',
            '#FF8066',
            '#FF8066',
            '#FF8066',
            '#FF8066',
            '#FF8066',
            '#FF8066',
            '#FF8066',
            '#FF8066',
            '#FF8066',
          ],
          // label: "Grammatical Errors"
        },
      ],
      labels: [
        'Incorrect subject-verb agreement',
        'Wrong tense or verb form',
        'Incorrect singular/plural agreement',
        'Incorrect word form',
        'Unclear pronoun reference',
        'Incorrect use of articles',
        'Wrong or missing prepositions',
        'Omitted commas',
        'Too many commas',
        'Possessive apostrophe error',
        'Incorrect word use',
      ],
    };

    this.sentimentAnalysisData = {
      labels: ['Greed', 'Fear', 'Curiosity', 'Helpfulness', 'Urgency'],
      datasets: [
        {
          label: 'Sentiment Analysis',
          backgroundColor: 'rgba(179,181,198,0.2)',
          borderColor: 'rgba(179,181,198,1)',
          pointBackgroundColor: 'rgba(179,181,198,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(179,181,198,1)',
          data: [30, 70, 90, 40, 50],
        },
      ],
    };
  }

}


