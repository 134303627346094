<ui-breadcrumbs [customLabels]="['File Scanner']"></ui-breadcrumbs>

<div class="drop-shadow-lg grid grid-cols-1 mx-4 md:mx-16 mb-16 rounded-lg bg-white fade-in-left">

    <div class="mx-8 my-8">

        <h2>File Analyzer Sandbox:</h2>
        <span class="text-gray-400">Drag and Drop your file and scan it agaist a powerful last-generation malware
            scanner, with Yara rules!</span>

        <div class="mt-8">
            <p-fileUpload [customUpload]="true" (uploadHandler)="myUploader($event)" (onClear)="onClearHandler($event)"
                [multiple]="true" [maxFileSize]="20000000">
                <ng-template pTemplate="content">
                    Drag And Drop Files Here (max size 20MB)
                </ng-template>
            </p-fileUpload>
        </div>

        <div *ngIf="scannedFiles.length" class="mt-8">
            <h2>Results:</h2>
            <div class="flex">
                <div *ngIf="loading">
                    <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i> Loading ...
                </div>
                <div>
                    Scanned files {{ cntScanned}} / {{ cntTot }}
                </div>
            </div>
            <div class="mt-8 flex flex-wrap gap-4">
                <div *ngFor="let file of scannedFiles" class="border rounded px-4 py-4 max-w-64 min-w-64 break-all">
                    <div class="flex justify-center">
                        <div *ngIf="file.clamAVScan === 'OK'; else virus ">
                            <img src="assets/safe.png" alt="success" class="h-16 w-16">
                            <!-- <span class="text-gray-400">ClamAV Scan:</span> <span class="text-green-400">File is Safe</span>
                            {{ file.clamAVScan }} -->
                        </div>
                        <ng-template #virus>
                            <img src="assets/warn.png" alt="error" class="h-16 w-16">
                            <!-- <span class="text-gray-400">ClamAV Scan:</span> <span class="text-red-500">Virus
                                Detected:</span> {{ file.clamAVScan }} -->
                        </ng-template>
                    </div>
                    <div class="flex justify-center">
                        <div class="mt-4"><span class="text-gray-400"></span> {{ file.fileName }}</div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="flex justify-end mr-8 mb-2">
        <small>
            Powered with ClamAV by Cisco Talos
        </small>
    </div>
</div>