import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataSharingService } from '@phishbowl/shared';

@Component({
  selector: 'ui-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss',
    '../../style.scss'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SummaryComponent implements OnInit{
  subject!: string;
  messageID!: string;
  from!: string;
  to!: string;
  date!: string;  
  summary: any;
  spinner: boolean = true;

  constructor(
    private dataSharingService: DataSharingService,
  ) { }

  ngOnInit(): void {
    
    this.dataSharingService.summaryLoading
      .subscribe((loading) => {
        // Use the loading state to display/hide the loading spinner
        this.spinner = true;
      });
    
      this.dataSharingService.summary
      .subscribe((summary) => {
        if (summary !== null) {
          // Update the analysis only when it's not null
          this.summary = summary;
          summary.forEach((element: any) => {
            if (element.name === 'Subject') {
              this.subject = element.value;
            }
            if (element.name === 'Message-ID') {
              this.messageID = element.value;
            }
            if (element.name === 'From') {
              this.from = element.value;
            }
            if (element.name === 'To') {
              this.to = element.value;
            }
            if (element.name === 'Date') {
              this.date = element.value;
            }
          });
          this.spinner = false;
        } else {
          // Handle the case when marleneAnalysis is null (e.g., show a placeholder) Or any appropriate default value
          this.summary = {};
        }
      });

  }

}
