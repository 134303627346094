import { Component } from '@angular/core';

@Component({
  selector: 'phish-bowl-ui-attach',
  templateUrl: './attach.component.html',
  styleUrls: ['./attach.component.scss']
})
export class AttachComponent {

}
