<!-- DESKTOP -->
<div class="hidden lg:block">
  <div class="flex flex-wrap lg:flex-nowrap px-4 lg:px-8 justify-center gap-4 lg:gap-8">
    <div class="flex-none py-4 lg:py-8">
      <p-menu [model]="items"></p-menu>
    </div>
    <div class="flex-auto py-4 lg:py-8 overflow-x-auto">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<!-- MOBILE & TABLET -->
<div class="block lg:hidden">
  <div class="grid gap-4 px-4 md:gap-8 md:px-8">
    <!-- <ui-text-analyzer></ui-text-analyzer> -->
    <!-- <phish-bowl-ui-image-analyzer></phish-bowl-ui-image-analyzer> -->
    <ui-alida></ui-alida>
    <div class="overflow-x-auto">
      <ui-url-analyzer></ui-url-analyzer>
    </div>
    <ui-whois-v2></ui-whois-v2>
    <ui-certificate></ui-certificate>
    <ui-time-machine></ui-time-machine>
    <ui-body-row></ui-body-row>
  </div>
</div>