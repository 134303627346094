<!-- <p-toast></p-toast> -->
<!-- <div class="">
    <div>
        <h2>Alida Phishing Score:</h2>
    </div>
    <div>
        <p-knob [size]="200" [(ngModel)]="value" valueTemplate="{value}%" [disabled]="true"></p-knob>
    </div>
</div> -->

<div class=" grid grid-cols-1 lg:grid-cols-4 content-center gap-8 lg:gap-16">

    <!-- <ng-template #alidaScore> -->

    <!-- ALIDA -->
    <div class="col-span-1">
        <h2>Alida Phishing Score:</h2>

        <div *ngIf="spinner; else alidaScore" class="col-span-1">
            <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i> LOADING...
        </div>

        <ng-template #alidaScore>
            <div class="fade-in-left">
                <p-knob [size]="200" [(ngModel)]="value" valueTemplate="{value}%" [readonly]="true"></p-knob>
                <div *ngIf="this.value > 70">
                    <mat-icon mat-list-icon class="warning">warning</mat-icon>
                    <span class="text-red-600">Warning, high phishing score !</span>
                </div>
            </div>
        </ng-template>
    </div>

    <!-- SENTIMENT ANALYSIS -->
    <div class="col-span-1 lg:col-span-3">
        <h2>Sentiment Analysis</h2>

        <div *ngIf="spinner else sentimentAnalysis" class="col-span-1">
            <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i> LOADING...
        </div>

        <ng-template #sentimentAnalysis>
            <div class="fade-in-left">
                <p-chart type="bar" [data]="sentimentAnalysisData" [options]="radarChartOptions"></p-chart>
            </div>
        </ng-template>
    </div>

    <div class="col-span-1 lg:col-span-4">
        <ui-marlene></ui-marlene>
    </div>

    <!-- BODY EXTRACTED -->
    <div class="col-span-1 lg:col-span-4">
        <details>
            <summary>Email Body Content</summary>
            <div class="col-span-1 text-gray-300 rounded-lg px-8 pt-8 pb-4 fade-in-down" style="background-color: rgba(40, 43, 46)">
                <code>
<pre class="text-xs overflow-auto pb-4">
{{ analysis.body }}
</pre>
                            </code>
            </div>
        </details>
    </div>

</div>