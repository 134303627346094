import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataSharingService } from '@phishbowl/shared';
import { Subscription } from 'rxjs';
import {
  MarleneResponse
} from '@phishbowl/body-analyzer-client';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'ui-marlene',
  templateUrl: './marlene.component.html',
  styleUrls: ['./marlene.component.scss',
    '../../style.scss'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class MarleneComponent implements OnInit {

  spinner: boolean = true;
  subscriptions: Subscription[] = [];
  analysis: MarleneResponse = {};

  constructor(
    public dataSharingService: DataSharingService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {

    this.dataSharingService.marleneLoading
      .subscribe((loading) => {
        // Use the loading state to display/hide the loading spinner
        this.spinner = true;
      });

    this.dataSharingService.marleneAnalysis
      .subscribe((marleneAnalysis) => {
        if (marleneAnalysis !== null) {
          // Update the analysis only when it's not null
          this.analysis = marleneAnalysis;

          /* -------------- */

        // Iterate over each key in the results object
/*         for (const key in marleneAnalysis.results) {
          if (marleneAnalysis.results.hasOwnProperty(key)) {
              const classifications = marleneAnalysis.results[key]; // Array of classifications

              // Iterate over each classification in the array
              for (const classification of classifications) {
                  // Check for PhishingClassification and if it's not "NOT-Malicious General Class"
                  if (classification.hasOwnProperty('PhishingClassification') &&
                      classification['PhishingClassification'] !== "NOT-Malicious General Class") {
                      this.dataSharingService.marleneMalicius.next(true);
                  }

                  // Check for PolicyViolationClassification and if it's not "NOT-PolicyViolation"
                  if (classification.hasOwnProperty('PolicyViolationClassification') &&
                      classification['PolicyViolationClassification'] !== "NOT-PolicyViolation") {
                      this.dataSharingService.marlenePolicy.next(true);
                  }
              }
          }
      } */

          /* -------------- */

          this.spinner = false;
        } else {
          // Handle the case when marleneAnalysis is null (e.g., show a placeholder) Or any appropriate default value
          this.analysis = {};
        }
      });

  }

  getDisplayName(key: string): string {
    const mappings: { [key: string]: string } = {
      'PhishingClassification': 'Classification',
      'PolicyViolationClassification': 'Policy Violation',
      // Add more mappings as needed
    };
  
    // Return the mapped name if it exists, otherwise return the original key
    return mappings[key] || key;
  }
  

}

