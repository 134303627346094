import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MenuItem, MessageService } from 'primeng/api';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'shell-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {

  items: MenuItem[] | undefined;

  user='';

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private messageService: MessageService,
    private keycloakService: KeycloakService
  ) { }



  ngOnInit(): void {

    //this.user = this.keycloakService.getUsername();

    this.items = [
      {
        label: 'Menu',
        items: [
/*           {
            label: 'Dashboard',
            icon: 'pi pi-home',
            routerLink: '/dashboard'
          }, */
          {
            label: 'Email Analyzer',
            icon: 'pi pi-envelope',
            routerLink: '/'

          },
/*           {
            label: 'Find Report',
            icon: 'pi pi-search',
            routerLink: '/get-report/test'
          }, */
          {
            label: 'Report History',
            icon: 'pi pi-history',
            routerLink: '/report-history'
          },
          {
            label: 'Malware Analyzer',
            icon: 'pi pi-file',
            routerLink: '/malware-analyzer'
          },
          {
            label: 'Profile',
            icon: 'pi pi-user',
            routerLink: '/user'
          },
          {
            label: 'Sign Out',
            icon: 'pi pi-sign-out',
            command: () => {
              this.signOut();
            }
          },
/*           {
            label: 'Console Log',
            icon: 'pi pi-code',
            command: () => {
              this.consoleLog();
            }
          } */
        ]
      }
    ];
  }

  signOut() {
    //this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Data Updated' });

    //const redirectUri: string = window.location.origin + '';

    this.keycloakService.logout().then(() => {
      this.keycloakService.clearToken();
      console.log('Logged out successfully');
    }).catch((error) => {
      console.error('Logout error:', error);
    });
  }

  async consoleLog() {
    const loggedIn = await this.keycloakService.isLoggedIn();
    if (loggedIn) {
      try {
        //const userProfile = await this.keycloakService.loadUserProfile();
        //const userProfile = this.keycloakService.getKeycloakInstance().profile;
        const userProfile = this.keycloakService.getKeycloakInstance().idTokenParsed;
        console.log('userProfile', userProfile);
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      console.log('User is not logged in.');
    }
  }
  


}
