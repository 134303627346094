import { APP_INITIALIZER, NgModule, InjectionToken } from '@angular/core';
import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { MaterialModule } from '@phishbowl/material';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { BASE_PATH as HEADER_ANALYZER_BASE_PATH } from '@phishbowl/header-analyzer-client';
import { BASE_PATH as BODY_ANALYZER_BASE_PATH } from '@phishbowl/body-analyzer-client';
import { BASE_PATH as MALWARE_ANALYZER_BASE_PATH } from '@phishbowl/malware-analyzer-client';
import { BASE_PATH as SED_REPORT_BASE_PATH } from '@phishbowl/sed-report-client';
import { EMAIL_ANONYMIZER_BASE_PATH } from '@phishbowl/shared';
import { EnvironmentService } from '@phishbowl/environment';
import { AccordionModule } from 'primeng/accordion'; //accordion and accordion tab
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataSharingService } from '@phishbowl/shared';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from '../keycloak-init';

import { MenuModule } from 'primeng/menu';


@NgModule({
  declarations: [AppComponent, NavigationComponent, ToolbarComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MaterialModule,
    AccordionModule,
    BadgeModule,
    ButtonModule,
    ToastModule,
    KeycloakAngularModule,
    MenuModule
  ],
  providers: [
    MessageService,
    /* ReportControllerService, */
/*     { provide: HEADER_ANALYZER_BASE_PATH, useValue: '/header-analyzer' },
    { provide: BODY_ANALYZER_BASE_PATH, useValue: '/body-analyzer' },
    { provide: MALWARE_ANALYZER_BASE_PATH, useValue: '/malware-analyzer' }, */
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [EnvironmentService, DataSharingService],
      multi: true,
    },
    {
      provide: HEADER_ANALYZER_BASE_PATH,
      useFactory: headerAnalyzerBasePathFactory,
      deps: [DataSharingService],
    },
    {
      provide: BODY_ANALYZER_BASE_PATH,
      useFactory: bodyAnalyzerBasePathFactory,
      deps: [DataSharingService],
    },
    {
      provide: MALWARE_ANALYZER_BASE_PATH,
      useFactory: malwareAnalyzerBasePathFactory,
      deps: [DataSharingService],
    },
    {
      provide: SED_REPORT_BASE_PATH,
      useFactory: sedReportBasePathFactory,
      deps: [DataSharingService],
    },
    {
      provide: EMAIL_ANONYMIZER_BASE_PATH, 
      useFactory: emailAnonymizerBasePathFactory,
      deps: [DataSharingService],
    },
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

function appInitializerFactory(
  environmentService: EnvironmentService,
  dataSharingService: DataSharingService
): () => Promise<void> {
  return () =>
    environmentService
      .getEnvironment()
      .forEach((value) => {
        dataSharingService.headerAnalyzerBasePath.next(value.HEADER_ANALYZER_BASE_PATH);
        dataSharingService.bodyAnalyzerBasePath.next(value.BODY_ANALYZER_BASE_PATH);
        dataSharingService.malwareAnalyzerBasePath.next(value.MALWARE_ANALYZER_BASE_PATH);
        dataSharingService.sedReportBasePath.next(value.SED_REPORT_BASE_PATH);
        dataSharingService.emailAnonymizerBasePath.next(value.EMAIL_ANONYMIZER_BASE_PATH);
      });
}

function headerAnalyzerBasePathFactory(
  dataSharingService: DataSharingService
): string {
  return dataSharingService.headerAnalyzerBasePath.value;
}

function bodyAnalyzerBasePathFactory(
  dataSharingService: DataSharingService
): string {
  return dataSharingService.bodyAnalyzerBasePath.value;
}

function malwareAnalyzerBasePathFactory(
  dataSharingService: DataSharingService
): string {
  return dataSharingService.malwareAnalyzerBasePath.value;
}

function sedReportBasePathFactory(
  dataSharingService: DataSharingService
): string {
  return dataSharingService.sedReportBasePath.value;
}

function emailAnonymizerBasePathFactory(
  dataSharingService: DataSharingService
): string {
  return dataSharingService.emailAnonymizerBasePath.value;
}