<div *ngIf="spinner; else attachments" class="mx-8">
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
</div>


<ng-template #attachments>

    <div class="mx-8 mt-8">
        <div class="lg:pt-8">
            <h2>Attachments Analysis</h2>
        </div>
    </div>

    <div *ngIf="analysis">
        <div class="mx-4 lg:mx-8 fade-in-left">

            <div *ngIf="analysis.attachments?.length! > 0; else elseNoAttach">

                <h3><i>found {{ analysis.attachments?.length! }} attachments:</i></h3>

                <div class="grid place-items-stretch grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8">
                    <div *ngFor="let attachment of analysis.attachments; let indexOfelement=index;">
                        <div class="px-4 py-4 border border-gray-400 rounded-lg " [ngStyle]="{
                            'border-color': attachment.clamAVScan === 'OK' ? 'rgb(156, 163, 175)' : 'rgb(248, 113, 113)',
                            'border-width.px': attachment.clamAVScan === 'OK' ? 1 : 2
                            }">

                            <div class="break-all">
                                <h2><span> {{ indexOfelement + 1 }}. {{ attachment.extension }} File</span></h2>
                                <div><b>Name:</b> {{ attachment.fileName }}</div>
                                <div class=""><b>Size:</b> {{ bytesToKiloBytes(attachment.size!) | number: '1.0-0' }}
                                    KB</div>
                            </div>

                            <div class="break-all pt-2" *ngIf="attachment.extension == 'pdf' ">
                                <!-- Se il file è un pdf, allora stampo anche l analisi di Wings -->
                                <div>
                                    Artemis Machine Learning PDF Analysis
                                </div>
                                <div>
                                    <b>Infected: </b> {{ attachment.pdfFileAnalysis.is_infected }}
                                </div>
                                <div>
                                    <b>Phishing Probability:</b> {{ attachment.pdfFileAnalysis.infection_probability }}
                                </div>
                            </div>

                            <div class="break-all pt-2">
                                <p *ngIf="attachment.clamAVScan != 'OK'; else Safe">
                                    <span class="text-red-600">Virus Detected:</span>
                                    <code> {{ analysis.clamAVScan }}</code>
                                </p>
                                <ng-template #Safe>
                                    <span class="text-green-600">Attachment is Safe</span>
                                </ng-template>
                            </div>

                            Check also the section below:
                            <details class="mb-4 overflow-auto">
                                <summary>Threat Intelligence:</summary>
                                <div class="ml-2">
                                    <a [href]="'https://www.virustotal.com/gui/file/' +  attachment.md5"
                                        target="_blank">
                                        <!-- <p-tag value="Virustotal"></p-tag> -->
                                        <button
                                            class=" mx-1 my-1 bg-transparent hover:bg-blue-800 text-blue-700  text-xs hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded">
                                            Virustotal
                                        </button>
                                    </a>
                                    <a [href]="'https://app.any.run/submissions/#filehash:' +  attachment.md5"
                                        target="_blank">
                                        <!-- <p-tag value="AnyRun"></p-tag> -->
                                        <button
                                            class=" mx-1 my-1 bg-transparent hover:bg-blue-800 text-blue-700  text-xs hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded">
                                            AnyRun
                                        </button>
                                    </a>
                                    <a [href]="'https://www.hybrid-analysis.com/sample/' +  attachment.sha256"
                                        target="_blank">
                                        <!-- <p-tag value="Hybrid Analysis"></p-tag> -->
                                        <button
                                            class=" mx-1 my-1 bg-transparent hover:bg-blue-800 text-blue-700  text-xs hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded">
                                            Hybrid
                                        </button>
                                    </a>
                                    <a [href]="'https://labs.inquest.net/dfi/sha256/' +  attachment.sha256"
                                        target="_blank">
                                        <!-- <p-tag value="InQuest"></p-tag> -->
                                        <button
                                            class=" mx-1 my-1 bg-transparent hover:bg-blue-800 text-blue-700  text-xs hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded">
                                            InQuest
                                        </button>
                                    </a>
                                    <a [href]="'https://app.docguard.io/' +  attachment.sha256 + '/results/dashboard'"
                                        target="_blank">
                                        <!-- <p-tag value="docGuard"></p-tag> -->
                                        <button
                                            class=" mx-1 my-1 bg-transparent hover:bg-blue-800 text-blue-700  text-xs hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded">
                                            docGuard
                                        </button>
                                    </a>
                                    <a [href]="'https://yomi.yoroi.company/submissions/' +  attachment.sha256"
                                        target="_blank">
                                        <!-- <p-tag value="YOMI"></p-tag> -->
                                        <button
                                            class=" mx-1 my-1 bg-transparent hover:bg-blue-800 text-blue-700  text-xs hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded">
                                            YOMI
                                        </button>
                                    </a>
                                    <a [href]="'https://valkyrie.comodo.com/get_info?sha1=' +  attachment.sha1"
                                        target="_blank">
                                        <!-- <p-tag value="Valkyrie"></p-tag> -->
                                        <button
                                            class=" mx-1 my-1 bg-transparent hover:bg-blue-800 text-blue-700  text-xs hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded">
                                            Valkyrie
                                        </button>
                                    </a>
                                    <a [href]="'https://www.joesandbox.com/search?q=' +  attachment.md5"
                                        target="_blank">
                                        <!-- <p-tag value="JoeSandbox"></p-tag> -->
                                        <button
                                            class=" mx-1 my-1 bg-transparent hover:bg-blue-800 text-blue-700  text-xs hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded">
                                            Joe
                                        </button>
                                    </a>
                                    <a [href]="'https://malprob.io/report/' +  attachment.md5" target="_blank">
                                        <!-- <p-tag value="malprob"></p-tag> -->
                                        <button
                                            class=" mx-1 my-1 bg-transparent hover:bg-blue-800 text-blue-700  text-xs hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded">
                                            malprob
                                        </button>
                                    </a>
                                    <a [href]="'https://bazaar.abuse.ch/sample/' +  attachment.sha256 " target="_blank">
                                        <!-- <p-tag value="Baazar"></p-tag> -->
                                        <button
                                            class=" mx-1 my-1 bg-transparent hover:bg-blue-800 text-blue-700  text-xs hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded">
                                            Baazar
                                        </button>
                                    </a>
                                </div>
                            </details>

                        </div>
                    </div>
                </div>

            </div>
            <ng-template #elseNoAttach>
                <div *ngIf="analysis.extension === 'eml'" class="pl-2 fade-in-left">
                    No attachments.
                </div>
            </ng-template>
        </div>
    </div>

</ng-template>