<div class=" grid grid-cols-1 xl:grid-cols-4 content-center gap-8 lg:gap-16">
    <div class="col-span-4">
        <h2>Marlene Phishing Classifier:</h2>

        <div *ngIf="spinner; else marleneClassifier" class="col-span-1">
            <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i> LOADING...
        </div>

        <ng-template #marleneClassifier>
            <div *ngFor="let result of analysis.results | keyvalue" class="fade-in-down">
                <div *ngFor="let item of result.value">
                    <div *ngFor="let prop of item | keyvalue">
                        <span class="text-base">{{ getDisplayName(prop.key) }}: <b>{{ prop.value }}</b></span>
                    </div>
                </div>
            </div>
        </ng-template>
        

    </div>
</div>