import { Component } from '@angular/core';
import { DataSharingService } from '@phishbowl/shared';
import { filter, mergeMap, share, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  UrlControllerService,
  URLAnalysis,
} from '@phishbowl/body-analyzer-client';

//import { callJSFun } from '../whois/javascript.js';

export interface Whois {
  url: URLAnalysis;
  whois: string;
  created?: string;
}

@Component({
  selector: 'ui-whois',
  templateUrl: './whois.component.html',
  styleUrls: ['./whois.component.scss']
})
export class WhoisComponent {

  whois: Whois[] = [];
  urls: URLAnalysis[] = [];
  date: Date = new Date();

  constructor(
    private dataSharingService: DataSharingService,
    private http: HttpClient,
    private urlService: UrlControllerService
  ) { }

  ngOnInit(): void {
    //this.text = callJSFun();

    const emlFile = this.dataSharingService.emlFile.getValue();

    if (emlFile != null) {
      this.urlService
        .urlAnalysis(emlFile)
        .pipe(take(1))
        .subscribe((urls) => {
          this.urls = urls;
        });
    }

    const file$: Observable<File> = this.dataSharingService.emlFile
      .pipe(filter((file): file is File => !!file))
      .pipe(share());

    file$
      .pipe(mergeMap((file) => this.urlService.urlAnalysis(file).pipe(take(1))))
      .subscribe((urls: URLAnalysis[]) => {

        this.urls = urls;
        urls.forEach((url) => {

          //get whois data
          //http://localhost:4200/api/v2?key=9E7A81B57E3138F73852608A822DD5CF&domain=https://link.pblc.it/c/637760573?alt_obj=hre&method=email&url=
          this.http.get('http://localhost:4200/api/v2?key=9E7A81B57E3138F73852608A822DD5CF&domain=' + url.url, { responseType: 'text' }).subscribe(data => {

            if (data) {
              this.whois.push({ url: url, whois: JSON.parse(data), created: JSON.parse(data).create_date });
            } else {
              this.whois.push({ url: url, whois: "" });
            }
          });


          /*           var text = "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iste tempore unde cupiditate facere voluptatum dignissimos facilis expedita, corporis blanditiis! Inventore excepturi aliquid cumque ab et dolore nisi error est magnam!";
                    this.whois.push({ url: url, whois: text }); */

        });

      });

  }

  differenceInDays(date: string): number {
    return Math.floor((new Date().getTime() - new Date(date).getTime()) / (1000 * 3600 * 24));
  }

}
