import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DataSharingService } from '@phishbowl/shared';
import { filter, mergeMap, share, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BodyControllerService } from '@phishbowl/body-analyzer-client';
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'ui-body-row',
  templateUrl: './body-row.component.html',
  styleUrls: ['./body-row.component.scss',
    '../../style.scss'
  ],
  encapsulation: ViewEncapsulation.None,
})

export class BodyRowComponent implements OnInit {
  public text: string = "";
  spinner: boolean = true;

  @ViewChild('area')
  area!: ElementRef<HTMLTextAreaElement>;

  constructor(
    private dataSharingService: DataSharingService,
    private bodyControllerService: BodyControllerService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {

    this.dataSharingService.bodyRawLoadind
      .subscribe((loading) => {
        // Use the loading state to display/hide the loading spinner
        this.spinner = true;
      });

    this.dataSharingService.bodyRaw
      .subscribe((bodyRaw) => {
        if (bodyRaw !== null) {
          // Update the analysis only when it's not null
          this.text = bodyRaw;
          this.spinner = false;
        } else {
          // Handle the case when headersRaw is null (e.g., show a placeholder) Or any appropriate default value
          this.text = '';
        }
      });

  }

  copyText() {
    this.area.nativeElement.select();
    document.execCommand('copy');
    this.area.nativeElement.setSelectionRange(0, 0);
    this.snackBar.open('Email header copied!', 'Ok', { duration: 2000 });
  }

  get textValue() {
    return this.text;
  }
}
