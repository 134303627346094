import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportControllerService } from '@phishbowl/sed-report-client';
import { DataSharingService } from '../../data-sharing/data-sharing.service';

@Component({
  selector: 'ui-get-report',
  templateUrl: './get-report.component.html',
  styleUrls: ['./get-report.component.scss']
})
export class GetReportComponent implements OnInit, OnDestroy{
  uid: string | undefined;
  reportFound = false;
  report: any;
  loading = true; // Added loading variable


  constructor(
    private route: ActivatedRoute,
    private reportControllerService: ReportControllerService,
    private datasSharingService: DataSharingService
  ) { }

  ngOnInit(): void {
    // Retrieve the UID from the route parameters
    this.route.params.subscribe((params) => {
      this.uid = params['uid'];

      // Use the ReportControllerService to get the report
      if (this.uid) {
        this.reportControllerService.getReport(this.uid).subscribe((response: any) => {
          // Handle the response from the API
          this.report = response;

          //header
          this.datasSharingService.summary.next(this.report.HeaderAnalysis.Summary);
          this.datasSharingService.hops.next(this.report.HeaderAnalysis.Hops);
          this.datasSharingService.auth.next(this.report.HeaderAnalysis.Authentication);
          this.datasSharingService.headersRaw.next(this.report.HeaderAnalysis.HeaderRaw);

          //body
          this.datasSharingService.textAnalysis.next(this.report.BodyAnalysis.TextAnalysis);
          this.datasSharingService.textAnalysisSyn.next(this.report.BodyAnalysis.TextAnalysisSyn)
          this.datasSharingService.marleneAnalysis.next(this.report.BodyAnalysis.Marlene);
          this.datasSharingService.bodyRaw.next(this.report.BodyAnalysis.BodyRaw);
          this.datasSharingService.urlAnalysis.next(this.report.BodyAnalysis.UrlAnalysis);


          //attach
          this.datasSharingService.malwareAnalysis.next(this.report.MalwareAnalysis);

          this.reportFound = true;
          this.loading = false; // Set loading to false when data is received

        },
          (error: any) => {
            // Handle error
            console.error('Error fetching report:', error);
            this.loading = false; // Set loading to false in case of an error
          }

        );
      } else {
        // Handle the case where the UID is not defined
        this.reportFound = false;
        this.loading = false; // Set loading to false when UID is not defined

      }

    });
  }

  ngOnDestroy(): void {
        // Reset the data when the component is destroyed
        this.datasSharingService.summary.next(null);
        this.datasSharingService.hops.next(null);
        this.datasSharingService.auth.next(null);
        this.datasSharingService.headersRaw.next(null);
        this.datasSharingService.textAnalysis.next(null);
        this.datasSharingService.textAnalysisSyn.next(null);
        this.datasSharingService.marleneAnalysis.next(null);
        this.datasSharingService.bodyRaw.next(null);
        this.datasSharingService.urlAnalysis.next(null);
        this.datasSharingService.malwareAnalysis.next(null);
        this.datasSharingService.emlFile.next(null);
  }

  revealEasterEgg() {
    // Get the Easter egg element
    const easterEgg = document.getElementById("easterEgg");

    if (easterEgg) {
      // If Easter egg is hidden, reveal it
      if (easterEgg.style.display === "none") {
        easterEgg.style.display = "block";
      }
    }

  }

}
