<h2>Urls Certificates</h2>

<div *ngIf="analysis.length < 1 && !spinner;" class="pl-4 fade-in-left">
    No Urls Found in the body of the email.
</div>

<div *ngIf="spinner; else certificateBody" class="pl-4">
    <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i> LOADING...
</div>

<ng-template #certificateBody>

    <div *ngFor="let url of orderArray(analysis, 'url')" class="grid grid-cols-1 break-words fade-in-down">

        <div>
            <b>URL: </b> <span class="text-xs">{{url.url}}</span> <br>

            <span *ngIf="url.certificates && (url.certificates.length > 0); else redCertificate" class="">
                <span class="">Found {{url.certificates.length}} certificates</span>
            </span>

            <ng-template #redCertificate>
                <div class="flex">
                    <mat-icon mat-list-icon class="warning">warning</mat-icon>
                    <span class=" text-red-600">
                        Found {{url.certificates?.length}} certificates</span>
                </div>
            </ng-template>


        </div>


        <div *ngFor="let certificate of url.certificates let i=index" class=" mt-2">
            <details>
                <summary>Certificate {{i+1}}</summary>
                <div class="col-span-1 text-gray-300 rounded-lg px-8 pt-8 pb-4 fade-in-left"
                    style="background-color: rgba(40, 43, 46)">
                    <code>
<pre class="text-xs overflow-auto pb-4">
<b>version:</b> {{certificate.version}}
<b>serialNumber:</b> {{certificate.serialNumber}}
<b>subject:</b> {{certificate.subject}}
<b>issuer:</b> {{certificate.issuer}}
<b>notBefore:</b> {{certificate.notBefore}}
<b>notAfter:</b> {{certificate.notAfter}}
<b>publicKey:</b> {{certificate.publicKey}}
<b>publicKeyAlgorithm:</b> {{certificate.publicKeyAlgorithm}}
<b>signature:</b> {{certificate.signature}}
<b>signatureAlgorithm:</b> {{certificate.signatureAlgorithm}}
<b>md5Fingerprint:</b> {{certificate.md5Fingerprint}}
<b>sha1Fingerprint:</b> {{certificate.sha1Fingerprint}}
<b>sha256Fingerprint:</b> {{certificate.sha256Fingerprint}}
</pre>
                    </code>
                </div>
            </details>
        </div>

        <p-divider class="block lg:hidden mt-2"></p-divider>
        <p-divider class="hidden lg:block mt-4"></p-divider>

    </div>

</ng-template>