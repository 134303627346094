import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataSharingService } from '@phishbowl/shared';
import { URLAnalysis } from '@phishbowl/body-analyzer-client';

@Component({
  selector: 'ui-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss',
    '../../style.scss'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CertificateComponent implements OnInit {

  spinner: boolean = true;
  analysis: URLAnalysis[] = [];

  constructor(
    public dataSharingService: DataSharingService,
  ) { }


  ngOnInit(): void {

    this.dataSharingService.urlAnalysisLoadind
      .subscribe((loading) => {
        // Use the loading state to display/hide the loading spinner
        this.spinner = true;
      });

    this.dataSharingService.urlAnalysis
      .subscribe((urlAnalysis) => {
        if (urlAnalysis !== null) {
          // Update the analysis only when it's not null
          this.analysis = urlAnalysis;
          // count if some url miss certificates
/*           let countCert = 0;
          this.analysis.forEach((url) => {
            if (!url.certificates || url.certificates.length === 0) {
              countCert++;
            }
          });
          this.dataSharingService.certificatesThreat.next(countCert); */
          this.spinner = false;
        } else {
          // Handle the case when marleneAnalysis is null (e.g., show a placeholder) Or any appropriate default value
          this.analysis = [];
        }
      });

  }

  /* order array of object */
  orderArray(array: any[], key: string): any[] {
    return array.sort((a, b) => {
      if (a[key] < b[key]) {
        return -1;
      } else if (a[key] > b[key]) {
        return 1;
      } else {
        return 0;
      }
    });
  }

}
