import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'ui-body-analyzer',
  templateUrl: './body-analyzer.component.html',
  styleUrls: ['./body-analyzer.component.scss'],
})
export class BodyAnalyzerComponent implements OnInit {
  items: MenuItem[] = [];

  ngOnInit(): void {
    this.menuItems();
  }

  private menuItems() {
    this.items = [
      {
        label: 'Content',
        items: [
          {
            /* label: 'Alida', */
            label: 'AI NLP',
            icon: 'pi pi-chart-bar',
            routerLink: 'alida',
          },
/*           {
            label: 'Text-Analysis',
            icon: 'pi pi-chart-pie',
            routerLink: 'emoroberta',
          }, */
/*           {
            label: 'Marlene',
            icon: 'pi pi-tags',
            routerLink: 'marlene',
          }, */
/*           {
            label: 'Text',
            icon: 'pi pi-align-left',
            routerLink: 'text',
          }, */
/*           {
            label: 'Image',
            icon: 'pi pi-image',
            routerLink: 'image',
          }, */
          {
            label: 'HTML',
            icon: 'pi pi-code',
            routerLink: 'html',
          },
        ]
      },
      {
        label: 'URL',
        items: [
          {
            label: 'Blocklist',
            icon: 'pi pi-link',
            routerLink: 'url',
          },
          {
            label: 'WHOIS',
            icon: 'pi pi-id-card',
            routerLink: 'whois2',
          },
          {
            label: 'Certificate',
            icon: 'pi pi-lock',
            routerLink: 'certificate',
          },
          {
            label: 'Time Machine',
            icon: 'pi pi-clock',
            routerLink: 'snapshot',
          },
        ]
      },

    ];
  }

}
