<h2>Received Headers:</h2>
<span class="text-gray-400 pb-4">The servers/computers through which the message traveled in order to
  reach you</span>
<div class="fade-in-down">
  <table mat-table [dataSource]="dataSource" matSort matSortDisableClear>
    <!-- Order Column -->
    <ng-container matColumnDef="order">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Hop</th>
      <td mat-cell *matCellDef="let hop">{{ hop.order }}</td>
    </ng-container>

    <!-- From Column -->
    <ng-container matColumnDef="from">
      <th mat-header-cell *matHeaderCellDef>From</th>
      <td mat-cell *matCellDef="let hop">
        <span *ngIf="hop.from">
          <span *ngIf="hop.from.hostName"> {{hop.from.hostName}} <br> </span>
          <span *ngIf="hop.from.verificationData?.ipv4 || hop.from.verificationData?.ipv6">
            {{hop.from.verificationData?.ipv4 || hop.from.verificationData?.ipv6}} </span>

          <span  *ngIf="hop.from.location != null " [matTooltip]="'#'" matTooltipPosition="above">
            <span *ngIf="hop.from.location != null " [class]="'flag-icon flag-icon-' + hop.from.location.toLowerCase()"
              style="border-radius: 2px; margin-left: 4px;"></span>
          </span>

<!--           <span *ngIf="hop.from.location?.latitude && hop.from.location?.longitude"><b>Location:</b> <a target="_blank"
              href="http://maps.google.com/maps?q={{hop.from.location?.latitude}},{{hop.from.location?.longitude}}">({{hop.from.location?.latitude}},
              {{hop.from.location?.longitude}})</a>
          </span> -->

        </span>
      </td>
    </ng-container>

    <!-- By Column -->
    <ng-container matColumnDef="by">
      <th mat-header-cell *matHeaderCellDef>By</th>
      <td mat-cell *matCellDef="let hop">
        <span *ngIf="hop.by">
          <span *ngIf="hop.by.hostName; else elseBlock">
            {{hop.by.hostName}} <br> </span>
          <ng-template #elseBlock> {{hop.by.raw}}</ng-template>
          <span *ngIf="hop.by.verificationData?.ipv4 || hop.by.verificationData?.ipv6">
            {{hop.by.verificationData?.ipv4 || hop.by.verificationData?.ipv6}}</span>
            <span *ngIf="hop.by.location != null" [matTooltip]="''" matTooltipPosition="above">
              <span *ngIf="hop.by.location != null " [class]="'flag-icon flag-icon-' + hop.by.location.toLowerCase()"
                style="border-radius: 2px; margin-left: 4px;"></span>
            </span>
<!--           <span *ngIf="hop.by.location?.latitude && hop.by.location?.longitude"><b>Location:</b> <a target="_blank"
              href="http://maps.google.com/maps?q={{hop.by.location?.latitude}},{{hop.by.location?.longitude}}">({{hop.by.location?.latitude}},
              {{hop.by.location?.longitude}})</a></span> -->
        </span>
      </td>
    </ng-container>

    <!-- With Column -->
    <ng-container matColumnDef="with">
      <th mat-header-cell *matHeaderCellDef>With</th>
      <td mat-cell *matCellDef="let hop">
        <span *ngIf="hop.with.mailProtocol">{{ hop.with.mailProtocol }} <br> </span>
      </td>
    </ng-container>

    <!-- Time Column -->
    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef>Time</th>
      <td mat-cell *matCellDef="let hop">
        <span *ngIf="hop.time">
          {{hop.time*1000 | date:'dd MMM yyyy' : 'UTC'}} {{ hop.time*1000 | date:'HH:mm:ss' : 'UTC'}}<span
            class="text-xs">&ensp;(UTC)</span>
        </span>
      </td>
    </ng-container>

    <!-- Delay Column -->
    <ng-container matColumnDef="delay">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Delay</th>
      <td mat-cell *matCellDef="let hop">
        <span *ngIf="hop.delay < 120000; else redDelay">
          {{ hop.delay | delay }}
        </span>
        <ng-template #redDelay>
          <span class="text-red-600">
            {{ hop.delay | delay }}
          </span>
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator class="pt-4" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>