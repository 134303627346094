<div class="">
  <!-- RAW -->
  <div class="">
    <div class="">
      <h2>Raw Content:</h2>
      <!--  <span class="text-gray-400">The Original of Email</span> -->
    </div>

    <div *ngIf="spinner; else bodyRaw" class="col-span-1">
      <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i> LOADING...
    </div>

    <ng-template #bodyRaw>
      <div class="flex fade-in-down">
        <div class="flex-auto pr-8">
          <!-- TEXT FIELD -->
          <textarea #area class="example-full-width" matInput matTextareaAutosize="true" rows="10" placeholder=""
            *ngIf="text" readonly>{{ text }}</textarea>
        </div>
        <!-- COPY BUTTON -->
        <div class="mr-8">
          <button *ngIf="text" mat-icon-button matTooltip="Copy" matTooltipPosition="above" (click)="copyText()">
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
      </div>
    </ng-template>

  </div>