import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakTokenParsed } from 'keycloak-js';
import { ReportControllerService } from '@phishbowl/sed-report-client';
import { Router } from '@angular/router';


@Component({
  selector: 'phish-bowl-ui-report-history',
  templateUrl: './report-history.component.html',
  styleUrls: ['./report-history.component.scss']
})
export class ReportHistoryComponent implements OnInit {

  userProfile: KeycloakTokenParsed | undefined;

  reports: any[] | undefined;
  userUid: string | undefined;
Object: any;

  constructor(
    private keycloakService: KeycloakService,
    private reportControllerService: ReportControllerService,
    private router: Router
  ) { }

  ngOnInit(): void {

    if (this.keycloakService.isLoggedIn()) {
      this.userProfile = this.keycloakService.getKeycloakInstance().idTokenParsed;

      if (this.userProfile) {
        this.userUid = this.userProfile.sub;
      }

      if (this.userUid) {
        this.reportControllerService.getReportByUser(this.userUid).subscribe(
          (data) => {
            this.reports = data as any[];
            this.reports = this.reports.map(report => this.transformReport(report)) as any[];
            this.reports = this.reports.map(report => this.transformMarlene(report)) as any[];
          },
          (error) => {
            this.reports = [];
          }
        );
      }

    }

  }

  private transformReport(report: any): any {
    const transformedSummary: { [key: string]: any } = {};
    report.HeaderAnalysis.Summary.forEach((item: { name: string; value: any; }) => {
      transformedSummary[item.name] = item.value;
    });
    return {
      ...report,
      transformedSummary, // Add the transformed summary to the report object
    };
  }  

  private transformMarlene(report: any): any {
    // Assuming Marlene.results.placeholder_key_0 is consistently structured
    if (report.BodyAnalysis.Marlene && report.BodyAnalysis.Marlene.results && report.BodyAnalysis.Marlene.results.placeholder_key_0) {
      const marleneResults = report.BodyAnalysis.Marlene.results.placeholder_key_0;
      const phishingClassification = marleneResults.find((result: any) => result.PhishingClassification)?.PhishingClassification;
      const policyViolationClassification = marleneResults.find((result: any) => result.PolicyViolationClassification)?.PolicyViolationClassification;
      
      // Adding PhishingClassification directly to the report for easier access
      return {
        ...report,
        PhishingClassification: phishingClassification, // This now directly includes the PhishingClassification in the report
        PolicyViolationClassification: policyViolationClassification, // This now directly includes the PolicyViolationClassification in the report
      };
    }
    // In case the structure is not as expected, return the report unchanged
    return report;
  }

  openReportDetails(reportId: string): void {
    this.router.navigate(['/get-report/', reportId]);
  }

  getAlidaPredictionText(report: any): string {
    const prediction = report.BodyAnalysis.TextAnalysis.alidaResponse.outputs.prediction[0][0];
    //return `Prediction: ${(prediction * 100).toFixed(2)}%`;
    return `${(prediction * 100).toFixed(2)}%`;
  }
  
  isAlidaPredictionMalicious(report: any): boolean {
    const prediction = report.BodyAnalysis.TextAnalysis.alidaResponse.outputs.prediction[0][0];
    return prediction >= 0.5;
  }

}
