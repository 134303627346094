<!-- <div *ngIf="userProfile" class="user-profile">
    <h2>User Profile</h2>
    <p><strong>First Name:</strong> {{ userProfile.given_name }}</p>
    <p><strong>Last Name:</strong> {{ userProfile.family_name }}</p>
    <p><strong>Email:</strong> {{ userProfile.email }}</p>
    <p><strong>Username:</strong> {{ userProfile.preferred_username }}</p>
    <p><strong>Uid:</strong> {{ userProfile.sub }}</p>
</div> -->

<ui-breadcrumbs [customLabels]="['Profile']"></ui-breadcrumbs>

<div class="drop-shadow-lg grid grid-cols-1 mx-4 md:mx-16 mb-16 rounded-lg bg-white fade-in-left">
    <div *ngIf="userProfile" class="user-profile" class="mx-8 my-8 fade-in-left">
        <div class="flex flex-wrap">
        
            <div class="self-center">
              <img class="mx-8 my-8 rounded-full w-32 h-32 border border-gray-200"
                src="assets/placeholder-image.jpg">
            </div>
        
            <div class="mx-8">
              <h1>Hello: <strong>{{(userProfile.preferred_username) ? userProfile.preferred_username : 'User'}}</strong></h1>
              <p><strong>First Name:</strong> {{ userProfile.given_name }}</p>
              <p><strong>Last Name:</strong> {{ userProfile.family_name }}</p>
              <p><strong>Email:</strong> {{ userProfile.email }}</p>
              <p><strong>Username:</strong> {{ userProfile.preferred_username }}</p>
              <p><strong>Uid:</strong> {{ userProfile.sub }}</p>
            </div>
        
        </div>
      </div>
</div>

