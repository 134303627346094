<div class="">
  <h2 style="margin-bottom: 0;">Message Authentication:</h2>

  <div *ngIf="spinner; else showAuth" class="col-span-1">
    <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i> LOADING...
  </div>

  <!-- FOR MOBILE/TABLET -->
  <ng-template #showAuth>

    <div class="lg:hidden">
      <mat-list>

        <mat-list-item>
          <ng-template [ngIf]="auth.dmarc==='Pass'" [ngIfElse]="second"><mat-icon mat-list-icon class="pass"
              [matTooltip]="'Pass'" matTooltipPosition="above">check_circle</mat-icon></ng-template>
          <ng-template #second>
            <ng-template [ngIf]="auth.dmarc==='Error'" [ngIfElse]="third"><mat-icon mat-list-icon class="fail"
                [matTooltip]="'Fail'" matTooltipPosition="above">cancel</mat-icon></ng-template>
          </ng-template>
          <ng-template #third>
            <ng-template [ngIf]="auth.dmarc==='NotFound'"><mat-icon mat-list-icon class="warning"
                [matTooltip]="'Not Found'" matTooltipPosition="above">warning</mat-icon></ng-template>
          </ng-template>
          DMARC
        </mat-list-item>
        <mat-list class="nested-list">
          <mat-list-item>
            <ng-template [ngIf]="auth.dkim==='Pass'" [ngIfElse]="second"><mat-icon mat-list-icon class="pass"
                [matTooltip]="'Pass'" matTooltipPosition="above">check_circle</mat-icon></ng-template>
            <ng-template #second>
              <ng-template [ngIf]="auth.dkim==='Error'" [ngIfElse]="third"><mat-icon mat-list-icon class="fail"
                  [matTooltip]="'Fail'" matTooltipPosition="above">cancel</mat-icon></ng-template>
            </ng-template>
            <ng-template #third>
              <ng-template [ngIf]="auth.dkim==='NotFound'"><mat-icon mat-list-icon class="warning"
                  [matTooltip]="'Not Found'" matTooltipPosition="above">warning</mat-icon></ng-template>
            </ng-template>
            DKIM
          </mat-list-item>
          <mat-list-item>
            <ng-template [ngIf]="auth.spf==='Pass'" [ngIfElse]="second"><mat-icon mat-list-icon class="pass"
                [matTooltip]="'Pass'" matTooltipPosition="above">check_circle</mat-icon></ng-template>
            <ng-template #second>
              <ng-template [ngIf]="auth.spf==='Error'" [ngIfElse]="third"><mat-icon mat-list-icon class="fail"
                  [matTooltip]="'Fail'" matTooltipPosition="above">cancel</mat-icon></ng-template>
            </ng-template>
            <ng-template #third>
              <ng-template [ngIf]="auth.spf==='NotFound'"><mat-icon mat-list-icon class="warning"
                  [matTooltip]="'Not Found'" matTooltipPosition="above">warning</mat-icon></ng-template>
            </ng-template>
            SPF
          </mat-list-item>
        </mat-list>

      </mat-list>
    </div>

    <!-- FOR PC -->
    <!-- <div class="hidden lg:grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-16 mt-4 bg-white border border-gray-400 rounded-lg"> -->
    <div class="fade-in-down">
      <div class="hidden lg:grid grid-cols-2 md:grid-cols-3 gap-16 mt-4 bg-white border border-gray-400 rounded-lg">

        <div class="grid-span-1 px-4 py-4">
          <div class="flex">
            <b>SPF: </b>
            <ng-template [ngIf]="auth.spf==='Pass'" [ngIfElse]="second"><mat-icon mat-list-icon class="pass"
                [matTooltip]="'Pass'" matTooltipPosition="above">check_circle</mat-icon></ng-template>
            <ng-template #second>
              <ng-template [ngIf]="auth.spf==='Error'" [ngIfElse]="third"><mat-icon mat-list-icon class="fail"
                  [matTooltip]="'Fail'" matTooltipPosition="above">cancel</mat-icon></ng-template>
            </ng-template>
            <ng-template #third>
              <ng-template [ngIf]="auth.spf==='NotFound'"><mat-icon mat-list-icon class="warning"
                  [matTooltip]="'Not Found'" matTooltipPosition="above">warning</mat-icon></ng-template>
            </ng-template>
            <!--     {{auth.spf}} -->
          </div>
        </div>

        <div class="grid-span-1 px-4 py-4">
          <div class="flex">
            <b>DKIM: </b>
            <ng-template [ngIf]="auth.dkim==='Pass'" [ngIfElse]="second"><mat-icon mat-list-icon class="pass"
                [matTooltip]="'Pass'" matTooltipPosition="above">check_circle</mat-icon></ng-template>
            <ng-template #second>
              <ng-template [ngIf]="auth.dkim==='Error'" [ngIfElse]="third"><mat-icon mat-list-icon class="fail"
                  [matTooltip]="'Fail'" matTooltipPosition="above">cancel</mat-icon></ng-template>
            </ng-template>
            <ng-template #third>
              <ng-template [ngIf]="auth.dkim==='NotFound'"><mat-icon mat-list-icon class="warning"
                  [matTooltip]="'Not Found'" matTooltipPosition="above">warning</mat-icon></ng-template>
            </ng-template>
            <!--     {{auth.dkim}} -->
          </div>
        </div>

        <div class="grid-span-1 px-4 py-4">
          <div class="flex">
            <b>DMARC: </b>
            <ng-template [ngIf]="auth.dmarc==='Pass'" [ngIfElse]="second"><mat-icon mat-list-icon class="pass"
                [matTooltip]="'Pass'" matTooltipPosition="above">check_circle</mat-icon></ng-template>
            <ng-template #second>
              <ng-template [ngIf]="auth.dmarc==='Error'" [ngIfElse]="third"><mat-icon mat-list-icon class="fail"
                  [matTooltip]="'Fail'" matTooltipPosition="above">cancel</mat-icon></ng-template>
            </ng-template>
            <ng-template #third>
              <ng-template [ngIf]="auth.dmarc==='NotFound'"><mat-icon mat-list-icon class="warning"
                  [matTooltip]="'Not Found'" matTooltipPosition="above">warning</mat-icon></ng-template>
            </ng-template>
            <!--    {{auth.dmarc}} -->
          </div>
        </div>

      </div>
    </div>

  </ng-template>

</div>