<h2>WHOIS Lookup</h2>

<div *ngIf="analysis.length < 1 && !spinner;" class="pl-4 fade-in-left">
    No Urls Found in the body of the email.
</div>

<div *ngIf="spinner; else whoisBody" class="pl-4">
    <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i> LOADING...
</div>

<ng-template #whoisBody>
    <div *ngFor="let url of orderArray(analysis, 'url')" class="grid grid-cols-1 break-words fade-in-down">

        <div>
            <b>URL: </b><span class="text-xs">{{url.url}}</span> <br>
        </div>

        <div class="col-span-1" *ngIf="url.whois?.created; else noWhoisBlock">
            <b>Domain: </b><span class="text-xs">{{url.whois?.domain}}</span> <br>
            <b>Created: </b><span class="text-xs">{{ url.whois?.created | date:'dd MMM yyyy HH:mm:ss' : 'UTC'
                }}</span><span class="text-xs">&ensp;(UTC)</span> <br>
            <span *ngIf="differenceInDays(url.whois?.created!) > 200; else redWhois">
                <div>
                    <b>Age: </b><span class="text-xs">{{ differenceInDays(url.whois?.created!) }} Days</span>
                </div>
            </span>
            <ng-template #redWhois>
                <div>
                    <b>Age: </b><span class="text-xs text-red-600">{{ differenceInDays(url.whois?.created!) }}
                        Days</span>
                </div>
            </ng-template>

            <!-- Controllo Dominio 2° livello -->
            <br>
            <span *ngIf=" removeWWW(url.whois?.domain?.toLowerCase()!) != removeWWW(url.host?.toLowerCase()!)">
                <div class="flex">
                    <mat-icon mat-list-icon class="warning">warning</mat-icon>
                    <!-- <span class="text-xs">This is not a second level domain</span> -->
                    <span class="text-xs">{{url.host}} is a <span class="text-red-600">subdomain</span> of
                        {{url.whois?.domain.toLowerCase()}}</span>
                </div>
            </span>

        </div>

        <ng-template #noWhoisBlock>
            <div class="ml-4 mt-2 mb-2 text-xs">
                WHOIS record not available for this domain.
            </div>
        </ng-template>

        <div *ngIf="url.whois?.created" class="mt-2 mb-4">
            <details>
                <div class="col-span-1 text-gray-300 rounded-lg p-8 fade-in-left"
                    style="background-color: rgba(40, 43, 46)">
                    <code>
<pre class="text-xs overflow-auto">
{{ url.whois?.raw }}
</pre>
                    </code>
                </div>
            </details>
        </div>

        <p-divider></p-divider>

    </div>

</ng-template>