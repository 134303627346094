<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'">
    <!-- Add This: [opened]="!(isHandset$ | async)" to controls the default open state -->

    <!-- <mat-divider></mat-divider> -->

    <mat-nav-list>
      <a mat-list-item href="#">Analyzer</a>
      <a mat-list-item href="#">Report</a>
      <a mat-list-item href="#">Malware SandBox</a>
      <a mat-list-item href="#">Profile</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>

    <mat-toolbar color="primary" class="toolbar-index">
      <!--   <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button> -->

      <!-- LOGO -->
      <button type="button" aria-label="Dashboard" [routerLink]="['']">
        <img src="assets/bitmap.png" class="mr-4" />
      </button>
      <button type="button" aria-label="Dashboard" [routerLink]="['']">
        <span calss="mr-4 text-2xl">SED</span>
      </button>
      <span class="example-spacer"></span>
      <!-- ICON RIGHT-->
      <p-menu #menu [model]="items" [popup]="true"></p-menu>
      <button pButton type="button" (click)="menu.toggle($event)" icon="pi pi-ellipsis-v"></button>
    </mat-toolbar>

    <!-- Add Content Here -->
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>