import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataSharingService } from '../../data-sharing/data-sharing.service';
import { AuthenticationControllerService, AuthenticationDTO } from '@phishbowl/header-analyzer-client';
import { Observable } from 'rxjs';
import { filter, mergeMap, share, take } from 'rxjs/operators';
import { count } from 'console';

@Component({
  selector: 'widget-auth-analysis',
  templateUrl: './auth-analysis.component.html',
  styleUrls: ['./auth-analysis.component.scss',
    '../../style.scss'
  ],
  encapsulation: ViewEncapsulation.None,
})

export class AuthAnalysisComponent implements OnInit {

  auth: AuthenticationDTO = {};
  spinner: boolean = true;


  /*   arc?: AuthenticationDTO.ArcEnum; 
    authenticationResultEnum = AuthenticationDTO.DmarcEnum;
    dkim?: AuthenticationDTO.DkimEnum;
    spf?: AuthenticationDTO.SpfEnum; */

  constructor(
    private dataSharingService: DataSharingService,
  ) { }

  ngOnInit(): void {

    this.dataSharingService.authLoading
      .subscribe((loading) => {
        // Use the loading state to display/hide the loading spinner
        this.spinner = true;
      });

    this.dataSharingService.auth
      .subscribe((auth) => {
        if (auth !== null) {
          // Update the analysis only when it's not null
          this.auth = auth;
          this.spinner = false;
        } else {
          // Handle the case when marleneAnalysis is null (e.g., show a placeholder) Or any appropriate default value
          this.auth = {};
        }
      });

  }

}
