<h2>WHOIS Lookup</h2>
<div *ngIf="whois.length < 1" class="pl-4">
    No Urls Found in the body of the email.
</div>
<div *ngFor="let url of whois" class="grid grid-cols-1 break-words">
    <div class="col-span-1">
        <span class="text-xs"><b>URL: </b>{{url.url.url}}</span> <br>
        <!-- <span class="text-xs"><b>Date Now: </b>{{ date | date:'dd MMM yyyy HH:mm:ss' : 'UTC' }}</span> -->
        <span class="text-xs"><b>Crated: </b>{{ url.created | date:'dd MMM yyyy HH:mm:ss' : 'UTC' }}</span><span class="text-xs">&ensp;(UTC)</span> <br>
        <span class="text-xs"><b>Age: </b>{{ differenceInDays(url.created!) }} Days</span>
    </div>

    <!-- MOBILE -->
    <div class="block lg:hidden">
        <details class="mb-4">
            <div class="col-span-1 text-gray-300 rounded-lg p-8"
            style="background-color: rgba(40, 43, 46)">
            <code>
                <pre class="text-xs overflow-auto">
                    {{ url.whois | json }}
                </pre>
            </code>
        </div>
        </details>
    </div>


    <!-- DESKTOP -->
    <div class="hidden lg:block">
        <p-fieldset legend="" [toggleable]="true" [collapsed]="true" class="mt-2 mb-8">
            <div class="col-span-1 text-gray-300 rounded-lg p-8 "
            style="background-color: rgba(40, 43, 46)">
            <code>
                <pre class="text-xs overflow-auto">
                    {{ url.whois | json }}
                </pre>
            </code>
        </div>
        </p-fieldset>
    </div>

</div>