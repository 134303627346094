import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportHistoryComponent } from 'libs/shared/src/lib/widget/report-history/report-history.component';
import { UserAccountComponent } from 'libs/shared/src/lib/widget/user-account/user-account.component';
import { MalwareAnalyzerComponent } from 'libs/shared/src/lib/widget/malware-analyzer/malware-analyzer.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./analyzer/analyzer.module').then((m) => m.AnalyzerModule),
  },
  {
    path: 'analyzer',
    loadChildren: () =>
      import('./analyzer/analyzer.module').then((m) => m.AnalyzerModule),
    data: {
      breadcrumb: 'Analyzer'
    },
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    data: {
      breadcrumb: 'Analyzer'
    },
  },
  {
    path: 'user',
    component: UserAccountComponent
  },
  {
    path: 'report-history',
    component: ReportHistoryComponent
  },
  {
    path: 'malware-analyzer',
    component: MalwareAnalyzerComponent
  },
  {
    path: 'analysis',
    loadChildren: () =>
      import('./analysis/analysis.module').then((m) => m.AnalysisModule),
    data: {
      breadcrumb: 'Hystory'
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
