import { Inject, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "apps/shell-module/shell/src/environments/environment";
import { APP_BASE_HREF } from "@angular/common";

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {

  constructor(private http: HttpClient, @Inject(APP_BASE_HREF) private baseHref: string) { }

  getEnvironment(): Observable<Record<string, string>> {
    if (environment.production) {
      return this.http.get<Record<string, string>>(this.baseHref + 'assets/env.prod.json');
    } else {
      return this.http.get<Record<string, string>>(this.baseHref + 'assets/env.json');
    }
  }
}