<h2>Summary:</h2>
<span class="text-gray-400">The main Email Information</span>

<div *ngIf="spinner; else summary" class="col-span-1 px-8 py-8">
  <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i> LOADING...
</div>

<ng-template #summary>
  <div class="fade-in-down">
    <div class="col-span-1" *ngIf="subject">
      <div class="bg-white border border-gray-400 rounded-lg break-all mt-4 px-8 py-8">
        <div class="overflow-auto">
          <div><b>Subject: </b>{{ subject }}</div>
          <div><b>Message ID: </b>{{ messageID }}</div>
          <div><b>From: </b>{{ from }}</div>
          <div><b>To: </b>{{ to }}</div>
          <div><b>Date: </b>{{ date | date:'dd MMM yyyy HH:mm:ss' : 'UTC'}}<span class="text-xs">&ensp;(UTC)</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>