<div class=" grid grid-cols-1 xl:grid-cols-2 content-center gap-8 lg:gap-16">

    <!-- Phishing Score -->
    <div class="col-span-1 grid">
        <h2>Phishing Score:</h2>

        <!-- <div *ngIf="this.dataSharingService.textAnalyzerSynLoading; else PhishingScore" class="col-span-1"> -->
        <div *ngIf="spinner; else PhishingScore" class="col-span-1">

            <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i> LOADING...
        </div>

        <ng-template #PhishingScore>
            <div class="fade-in-down justify-self-center">
                <p-knob [size]="200" [(ngModel)]="value" valueTemplate="{value}%" [disabled]="true"></p-knob>

                <div *ngIf="this.value > 70">
                    <mat-icon mat-list-icon class="warning">warning</mat-icon>
                    <span class="text-red-600">Warning, high phishing score !</span>
                </div>
            </div>
        </ng-template>
    </div>

    <!-- SENTIMENT ANALYSIS -->
    <div class="col-span-1">
        <h2>Sentiment Analysis</h2>

        <div *ngIf="spinner; else sentimentAnalysisSyn" class="col-span-1">
            <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i> LOADING...
        </div>

        <ng-template #sentimentAnalysisSyn>
            <div class="fade-in-down">
                <p-chart type="radar" height="16em" [data]="sentimentAnalysisData" [options]="radarChartOptions"></p-chart>
            </div>
        </ng-template>
    </div>

    <!-- BODY EXTRACTED -->
    <div class="col-span-1 lg:col-span-4">

        <details>
            <summary>Email Body Content</summary>
            <div class="col-span-1 text-gray-300 rounded-lg px-8 pt-8 pb-4 " style="background-color: rgba(40, 43, 46)">
                <code>
<pre class="text-xs overflow-auto pb-4">
{{ this.analysis.body }}
</pre>
                            </code>
            </div>
        </details>

    </div>



<!--     <div> -->
        <!-- print all element inside analysis -->
        <!-- <pre>{{ analysis | json }}</pre> -->
        <!-- print datasharing filename -->
<!--         <pre>{{ dataSharingService.name1 | json }}
             {{ dataSharingService.name2 | json }}
        </pre> -->
<!--     </div> -->

</div>