import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@phishbowl/material';
import { DelayPipe } from './pipe/delay.pipe';
import { AuthAnalysisComponent } from './widget/auth-analysis/auth-analysis.component';
import { BodyAnalyzerComponent } from './widget/body-analyzer/body-analyzer.component';
import { MenuModule } from 'primeng/menu';
import { TextAnalyzerComponent } from './widget/text-analyzer/text-analyzer.component';
import { ChartModule } from 'primeng/chart';
import { UrlAnalyzerComponent } from './widget/url-analyzer/url-analyzer.component';
import { BodyRowComponent } from './widget/body-row/body-row.component';
import { AttachmentsComponent } from './widget/attachments/attachments.component';
import { FileUploadModule } from 'primeng/fileupload';
import { HttpClientModule } from '@angular/common/http';
import { BreadcrumbsComponent } from './widget/breadcrumbs/breadcrumbs.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { SummaryComponent } from './widget/summary/summary.component';
import { HeaderRowComponent } from './widget/header-row/header-row.component';
import { ImageAnalyzerComponent } from './widget/image-analyzer/image-analyzer.component';
import { ImageModule } from 'primeng/image';
import { HopsTableComponent } from './widget/hops-table/hops-table.component';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { DropdownModule } from 'primeng/dropdown';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FieldsetModule } from 'primeng/fieldset';
import { DividerModule } from 'primeng/divider';
import { TableModule } from 'primeng/table';
import { UserReportComponent } from './widget/user-report/user-report.component';
import { CardModule } from 'primeng/card';
import { MatMenuModule } from '@angular/material/menu';
import { WhoisComponent } from './widget/whois/whois.component';
import { PanelModule } from 'primeng/panel';
import { CertificateComponent } from './widget/certificate/certificate.component';
import { TagModule } from 'primeng/tag';
import { TimeMachineComponent } from './widget/time-machine/time-machine.component';
import { WhoisV2Component } from './widget/whois-v2/whois-v2.component';
import { AlidaComponent } from './widget/alida/alida.component';
import { ToastModule } from 'primeng/toast';
import { ProgressBarModule } from 'primeng/progressbar';
import { KnobModule } from 'primeng/knob';
import { FormsModule } from '@angular/forms';
import { HeaderAnalyzerComponent } from './widget/header-analyzer/header-analyzer.component';
import { TextAnalysisSynComponent } from './widget/text-analysis-syn/text-analysis-syn.component';
import { MarleneComponent } from './widget/marlene/marlene.component';
import { TimelineModule } from 'primeng/timeline';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GetReportComponent } from './widget/get-report/get-report.component';
import { HeaderComponent } from './widget/get-report/header/header.component';
import { BodyComponent } from './widget/get-report/body/body.component';
import { AttachComponent } from './widget/get-report/attach/attach.component';
import { UserAccountComponent } from './widget/user-account/user-account.component';
import { ReportHistoryComponent } from './widget/report-history/report-history.component';
import { MalwareAnalyzerComponent } from './widget/malware-analyzer/malware-analyzer.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    MenuModule,
    ChartModule,
    FileUploadModule,
    HttpClientModule,
    BreadcrumbModule,
    ImageModule,
    MessagesModule,
    MessageModule,
    DropdownModule,
    FontAwesomeModule,
    FieldsetModule,
    DividerModule,
    TableModule,
    CardModule,
    MatMenuModule,
    PanelModule,
    TagModule,
    ToastModule,
    ProgressBarModule,
    KnobModule,
    FormsModule,
    TimelineModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    DelayPipe,
    AuthAnalysisComponent,
    BodyAnalyzerComponent,
    TextAnalyzerComponent,
    UrlAnalyzerComponent,
    BodyRowComponent,
    AttachmentsComponent,
    BreadcrumbsComponent,
    SummaryComponent, 
    HeaderRowComponent, 
    ImageAnalyzerComponent, 
    HopsTableComponent, 
    UserReportComponent, 
    WhoisComponent, 
    CertificateComponent, 
    TimeMachineComponent, 
    WhoisV2Component, 
    AlidaComponent, 
    HeaderAnalyzerComponent, 
    TextAnalysisSynComponent, 
    MarleneComponent, GetReportComponent, HeaderComponent, BodyComponent, AttachComponent, UserAccountComponent, ReportHistoryComponent, MalwareAnalyzerComponent],
  exports: [
    DelayPipe,
    AuthAnalysisComponent,
    BodyAnalyzerComponent,
    TextAnalyzerComponent,
    UrlAnalyzerComponent,
    BodyRowComponent,
    AttachmentsComponent,
    BreadcrumbsComponent, 
    SummaryComponent,
    HeaderRowComponent, 
    ImageAnalyzerComponent,
    HopsTableComponent, 
    UserReportComponent, 
    WhoisComponent, 
    CertificateComponent, 
    TimeMachineComponent, 
    WhoisV2Component, 
    AlidaComponent, 
    HeaderAnalyzerComponent, 
    TextAnalysisSynComponent, 
    MarleneComponent, GetReportComponent, HeaderComponent, BodyComponent, AttachComponent, UserAccountComponent, ReportHistoryComponent, MalwareAnalyzerComponent],
})
export class SharedModule { }
