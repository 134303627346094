import { Injectable } from '@angular/core';
import Map from 'ol/Map';
import { Vector as VectorLayer } from 'ol/layer';
import { Coordinate } from 'ol/coordinate';
import { LineString } from 'ol/geom';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import VectorSource from 'ol/source/Vector';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Point from 'ol/geom/Point';
import Icon from 'ol/style/Icon';

@Injectable({
  providedIn: 'root',
})
export class MapToolsService {
  /**
   * Draws the path of the Hops on the provided map.
   * @param map
   * @param pathLayer
   * @param coordinates Longitude & Latitude coordinates.
   */
  public drawPath(
    map: Map,
    pathLayer: VectorLayer<VectorSource>,
    coordinates: Coordinate[]
  ) {
    const line = new LineString(coordinates);
    // Arrows
    line.forEachSegment((start, end) => {
      const dx = end[0] - start[0];
      const dy = end[1] - start[1];
      const rotation = Math.atan2(dy, dx);
    });

    const lineFeature = new Feature<Geometry>();
    lineFeature.setGeometry(line);

    const source = new VectorSource();
    source.addFeature(lineFeature);

    const style = new Style({
      fill: new Fill({ color: '#FF7733' }),
      stroke: new Stroke({ color: '#FF7733', width: 3 }),
    });

    const styles: Style[] = [];
    styles.push(style);
    styles.push(...this.addSegmentEndArrows(line));
    styles.push(this.addStartingPointIcon(line));

    if (pathLayer) {
      map.removeLayer(pathLayer);
    }

    pathLayer = new VectorLayer();
    pathLayer.setSource(source);
    pathLayer.setStyle(styles);

    map.addLayer(pathLayer);
  }

  private addSegmentEndArrows(line: LineString): Style[] {
    const styles: Style[] = [];

    line.forEachSegment((start, end) => {
      const dx = end[0] - start[0];
      const dy = end[1] - start[1];
      const rotation = Math.atan2(dy, dx);

      // Arrow point
      styles.push(
        new Style({
          geometry: new Point(end),
          image: new Icon({
            src: 'https://openlayers.org/en/latest/examples/data/arrow.png',
            anchor: [0.75, 0.5],
            rotateWithView: true,
            rotation: -rotation,
          }),
        })
      );
    });

    return styles;
  }

  private addStartingPointIcon(line: LineString): Style {
    const start = line.getFirstCoordinate();

    const style = new Style({
      geometry: new Point(start),
      image: new Icon({
        // http://www.softicons.com/transport-icons/space-rocket-icons-by-tomek-omelan/space-rocket-icon
        src: 'assets/rocket.png',
        scale: 0.8,
      }),
    });

    return style;
  }
}
