import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'delay',
})
export class DelayPipe implements PipeTransform {
  transform(milliseconds: number): string {
    const seconds: number = Math.floor(milliseconds / 1000);
    const minutes: number = Math.floor(seconds / 60);
    const hours: number = Math.floor(minutes / 60);
    const days: number = Math.floor(hours / 24);

    let delay = '';

    if (seconds > 0) {
      delay = (seconds % 60) + 's';

      if (minutes > 0) {
        delay = (minutes % 60) + 'm ' + delay;

        if (hours > 0) {
          delay = (hours % 24) + 'H ' + delay;

          if (days > 0) {
            delay = days + 'D ' + delay;
          }
        }
      }
    } else {
      delay = 0 + 's';
    }

    return delay;
  }
}
