<!-- RAW -->
<div class="">
  <h2>Raw Content:</h2>
  <span class="text-gray-400">The Original headers of Email</span>
</div>

<div *ngIf="spinner; else headersRaw" class="col-span-1">
  <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i> LOADING...
</div>

<ng-template #headersRaw>
  <div class="fade-in-down">
    <div class="flex">
      <div class="flex-auto mt-4 px-8 pb-8">
        <!-- TEXT FIELD -->
        <textarea #area2 class="example-full-width" matInput matTextareaAutosize="true" rows="10" placeholder=""
          *ngIf="text" readonly>{{ text }}</textarea>
      </div>
      <!-- COPY BUTTON -->
      <div class="mr-8 py-8">
        <button *ngIf="text" mat-icon-button matTooltip="Copy" matTooltipPosition="above" (click)="copyText()">
          <mat-icon>content_copy</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>